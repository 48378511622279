<template>
  <v-btn-toggle
    v-model="currentSelection"
    borderless
    active-class="button--active"
    class="button-group-container"
    :mandatory="isMandatory"
  >
    <template v-for="(item, index) in items">
      <v-btn
        :key="`${item.value}-button`"
        :value="item.value"
        :disabled="item.isDisabled"
        :ripple="false"
        height="20"
        depressed
        class="button"
        :data-testid="item.value"
      >
        <!-- Icon property takes precedence over title -->
        <v-icon v-if="item.icon">
          {{ item.icon }}
        </v-icon>
        <span
          v-else
          class="v-btn__content__text"
        >{{ item.title }}</span>
      </v-btn>
      <v-divider
        v-if="index !== items.length - 1"
        :key="`${item.value}-divider`"
        vertical
        class="mx-2"
      />
    </template>
  </v-btn-toggle>
</template>

<script setup lang="ts">
import { computed } from "vue";

type Button = {
  title?: string;
  value: number | string;
  icon?: string;
  isDisabled?: boolean;
};

type Props = {
  items: Array<Button>;
  value?: number | string;
  isMandatory?: boolean;
};

const emit = defineEmits<{
  (e: "input", value?: number | string): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  isMandatory: false,
});

const currentSelection = computed({
  get: () => props.value,
  set: (newValue?: number | string) => emit("input", newValue),
});
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/main.scss";

.v-btn-toggle.button-group-container {
  background: $backgrounds;
  border: 1px solid $elements;
  border-radius: 4px;
  padding: 7px;

  hr.v-divider {
    border-color: $elements;
  }

  button.v-btn.button {
    border-radius: 4px;
    @include subtitle-3;
    padding: 0;
    min-width: auto;
    background-color: $backgrounds;

    .v-btn__content {
      &__text {
        padding: 4px;
        color: $primary;
        text-transform: none;
      }

      .v-icon {
        color: $primary;
      }
    }

    &::before {
      opacity: 0;
    }

    &:hover {
      opacity: 1;
      background: $elements;

      .v-btn__content {
        &__text {
          color: $accent;
        }

        .v-icon {
          color: $accent;
        }
      }
    }

    &--active {
      background: $accentLight;

      .v-btn__content {
        &__text {
          color: $accent;
        }

        .v-icon {
          color: $accent;
        }
      }
    }

    &.v-btn--disabled {
      background-color: transparent !important;

      .v-btn__content {
        &__text {
          color: $secondaryLight;
        }

        .v-icon {
          color: $secondaryLight;
        }
      }
    }
  }
}
</style>
