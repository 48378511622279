<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    append-icon="$icon_arrow_down"
    filled
    dense
    :height="height"
    :solo="solo"
    :flat="flat"
    :outlined="outlined"
    class="select-box"
    :class="{ 'select-box--disabled': disabled }"
    :disabled="disabled"
    item-color="white"
    :menu-props="{
      bottom: true,
      offsetY: true,
      contentClass: lastNegative ? 'warningBox' : '',
      closeOnClick: closeOnClick
    }"
    return-object
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :rules="[...rules]"
    :item-text="itemText"
    :item-value="itemValue"
  >
    <template
      v-if="selectionSlot"
      #selection="{ item }"
    >
      <slot
        name="selection"
        :item="item"
      />
    </template>
    <template
      v-if="itemSlot"
      #item="{ item }"
    >
      <slot
        name="item"
        :item="item"
      />
    </template>
  </v-select>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

type Item = {
  text: string,
  value:  string,
}

type Props = {
  disabled?: boolean,
  value?: any,
  items: Item[],
  lastNegative?: boolean,
  itemSlot?: boolean,
  height?: string | number,
  selectionSlot?: boolean,
  solo?: boolean,
  flat?: boolean,
  outlined?: boolean,
  placeholder?: string,
  rules?: Array<string | boolean | ((value: {text: string, value: any}) => boolean | string)>,
  hideDetails?: boolean,
  closeOnClick?: boolean,
  itemText?: string,
  itemValue?: string
}

const props = withDefaults(defineProps<Props>(), {
  outlined: true,
  height: 36,
  hideDetails: true,
  closeOnClick: false,
  rules: () => []
});

const emit = defineEmits<{
  (e: 'input', value: any): void;
}>();

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    if (newValue !== props.value) {
      emit("input", newValue);
    }
  }
});

</script>

<style lang="scss">
@import "../../assets/styles/main";

.select-box .v-input__append-inner {
  margin-top: unset !important;
  align-self: center !important;
}

.select-box .v-input__slot {
  min-height: 36px !important;

  & fieldset {
    background: $backgrounds;
    color: $elements  !important;
    border: 1px solid;
  }

  & label {
    @include body-2;
  }

  &:hover {
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    border-radius: 4px;
  }

  &:active {
    & fieldset {
      background: $accentClear;
      border: 1px solid $accent;
    }
  }

  z-index: 2;

  .v-list-item--active {
    background: $accent  !important;
  }
}

.select-box {
  display: grid;

  .v-input__slot {
    min-height: 24px !important;
  }

  .v-select__selections {
    @include body-2;
  }

  .v-select__selection {
    color: $primary;
  }

  .v-list-item {
    min-height: 28px !important;
  }

  .v-list-item--link {
    &:before {
      border-radius: 4px !important;
    }
  }

  .v-input__icon--append {
    .v-icon {
      color: $secondaryMedium !important;
      caret-color: $secondaryMedium !important;

      svg {
        color: unset !important;
        caret-color: unset !important;
      }

      &.v-icon.v-icon--disabled {
        color: $secondaryLight !important;
      }
    }
  }

  .v-text-field__details {
    position: absolute;
    bottom: -24px;
    left: -12px;

    .v-messages__message {
      @include body-2;
    }
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative  !important;
    }
  }
}

.v-select-list {
  .v-list-item {
    height: 28px;
    .v-select__selection--comma, .v-list-item__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: 2px 4px 12px 0px #2a344014, 0 0 0 1px $elements;
  border-radius: 4px;
  background: white;
  margin-top: 4px;

  padding: {
    right: 4px;
    left: 4px;
  }
}

.select-box--disabled .v-input__slot {
  & fieldset {
    background: $elements;
  }
}

.warningBox>div>div:last-child {
  margin-top: 4px;

  &:hover {
    background: $negativeClear  !important;
  }

  .v-list-item__content {
    border-top: 1px solid $elements;
    color: $negative  !important;
  }
}
</style>
