<template>
  <button
    v-if="(latitude > -1) && (longitude > -1)"
    ref="cameraMarkerRef"
    class="camera-marker"
    :class="[
      `camera-marker-${camera.id}`,
      {
        'camera-marker--edit': isCameraEditEnabled && isFloorplanEditEnabled,
        'camera-marker--hovered': hoverable && isVisiblePopup,
        'camera-marker--online': cameraStatus === 'online',
        'camera-marker--offline': cameraStatus === 'offline',
      }]"
    :style="{ left: longitude + '%', top: latitude + '%'}"
  >
    <slot
      :on-leave-camera-elements="onLeaveCameraElements"
      :on-hover-camera-elements="onHoverCameraElements"
      :is-camera-hovered="hoverable && isCameraHovered"
      :is-visible-popup="isVisiblePopup"
      :popup-positions="popupPositions"
    />
    <div
      class="camera-marker__fov show-during-dragging"
      :draggable="isFloorplanEditEnabled"
      @dragstart="startDrag($event, camera)"
      @mousedown.stop
    >
      <svg
        v-show="cameraFOVShow"
        :height="currentCameraRange * 2"
        :width="currentCameraRange * 2"
      >
        <g
          :transform="`
              translate(${currentCameraRange}, ${currentCameraRange})
              rotate(${isEditingRangeAzimuth ? currentAzimuth : azimuth})
            `"
          @mouseover="onHoverCameraElements(camera.id)"
          @mouseleave="onLeaveCameraElements"
          @click="onClickCameraElement(camera)"
        >
          <circle
            v-if="fov === 360"
            :r="currentCameraRange"
            :cx="0"
            :cy="0"
          />
          <path
            v-else
            :d="`
                M0,0
                L${arcStart.x},${arcStart.y}
                A${currentCameraRange},${currentCameraRange},0,${largeArcFlag},1,${arcEnd.x},${arcEnd.y}
                Z
              `"
          />
        </g>
      </svg>
    </div>
    <div
      class="camera-marker__center show-during-dragging"
      :draggable="isFloorplanEditEnabled"
      @click="onClickCameraElement"
      @dragstart="startDrag($event, camera)"
      @mouseover="onHoverCameraElements(camera.id)"
      @mouseleave="onLeaveCameraElements"
      @mousedown.stop
    />

    <div
      v-show="cameraFOVShow && isFloorplanEditEnabled && isCameraEditEnabled"
      class="camera-marker__edit-scale"
      :style="`transform: rotate(calc(180deg + ${isEditingRangeAzimuth ? currentAzimuth : azimuth}deg))`"
    >
      <div
        ref="cameraMarkerEditCenter"
        class="camera-marker__edit-scale-center"
        :draggable="isFloorplanEditEnabled"
        @click="onClickCameraElement"
        @dragstart="startDrag($event, camera)"
        @mouseover="onHoverCameraElements(camera.id)"
        @mouseleave="onLeaveCameraElements"
        @mousedown.stop
      />
      <div
        class="camera-marker__edit-scale-line"
        :style="`height: ${(currentCameraRange) - 2}px;`"
      />
      <div
        class="camera-marker__edit-scale-end"
        @mousedown.stop.prevent="startEditAzimuthRange"
      />
    </div>

    <CameraLabel
      v-show="cameraLabelShow"
      :label="label"
      :is-active="isCameraEditEnabled && isFloorplanEditEnabled"
      :class="`camera-marker__label camera-marker__label--${labelPosition}`"
      :draggable="isFloorplanEditEnabled"
      @click="onClickCameraElement"
      @dragstart="startDrag($event, camera)"
      @mouseover="onHoverCameraElements(camera.id)"
      @mouseleave="onLeaveCameraElements"
      @mousedown.stop
    />
  </button>
</template>
<script setup lang="ts">
import CameraLabel from './CameraLabel.vue'
import {ApiCameraWithIncludes, FloorCoords} from "@/service/api-types";
import {computed, ref, ComputedRef, reactive} from "vue";

const emit = defineEmits<{
  (e: "click", camera: ApiCameraWithIncludes): void,
  (e: "mouseover"): void,
  (e: "dragstart", event: DragEvent): void,
  (e: "mouseleave"): void,
  (e: "changeRangeAzimuth", changes: { azimuth: number, rangeInMeters: number }): void
}>();
const props = withDefaults(defineProps<{
    fov: number,
    azimuth: number,
    cameraStatus: 'online' | 'offline',
    labelPosition: 'left' | 'right' | 'top' | 'bottom',
    label: string,
    latitude: number,
    longitude: number,
    isFloorplanEditEnabled: boolean,
    isCameraEditEnabled: boolean,
    cameraLabelShow: boolean,
    cameraFOVShow?: boolean,
    camera: ApiCameraWithIncludes,
    floorTopLeftLatitude: FloorCoords['latitude'],
    floorBottomRightLatitude: FloorCoords['latitude'],
    rangeInMeters: number,
    imageElementHeight: number,
    imageElementContainer: HTMLElement,
    isDragging: boolean,
    hoverable: boolean
  }>(),
  {
    hoverable: true,
    cameraFOVShow: true
  })

const {
  startEditAzimuthRange,
  isEditingRangeAzimuth,
  currentRangeInMeters,
  currentAzimuth,
  cameraMarkerEditCenter
} = useEditCameraAzimuthRange();

const isCameraHovered = ref(false);
const isVisiblePopup = ref(false);
const cameraMarkerRef = ref();
const referenceAngle = -90;
let willHidePopupTimer: ReturnType<typeof setTimeout> | undefined = undefined;
const popupPositions = reactive({
  onBottom: false,
})

const currentCameraRange: ComputedRef<number> = computed(() => {
  let rangeInMeters;

  if (isEditingRangeAzimuth.value) {
    rangeInMeters = currentRangeInMeters.value;
  } else {
    rangeInMeters = props.rangeInMeters ? props.rangeInMeters : 5
  }

  const longitudeFloorplanDistanceInMeters = getLatitudeFloorplanDistanceInMeters();
  const distanceInPixels = rangeInMeters / longitudeFloorplanDistanceInMeters * props.imageElementHeight;
  return distanceInPixels < 15 ? 15 : distanceInPixels;
})
const polarToCartesian = (distance: number, angleInDegrees: number) => {
  const angleInRadians = ((referenceAngle + angleInDegrees) * Math.PI) / 180.0;
  return {
    x: distance * Math.cos(angleInRadians),
    y: distance * Math.sin(angleInRadians)
  };
};
const arcStart = computed(() => {
  return polarToCartesian(currentCameraRange.value, -props.fov / 2);
})

const arcEnd = computed(() => {
  return polarToCartesian(currentCameraRange.value, props.fov / 2);
})

const largeArcFlag = computed(() => {
  return props.fov <= 180 ? "0" : "1";
})

function onHoverCameraElements(cameraId: string) {
  if (props.isCameraEditEnabled || props.isDragging) {
    return;
  }
  clearTimeout(willHidePopupTimer);
  setPositionOfPopup(cameraId);
  isCameraHovered.value = true;
  isVisiblePopup.value = true;
  emit('mouseover');
}

function onLeaveCameraElements() {
  clearTimeout(willHidePopupTimer);
  isCameraHovered.value = false;
  willHidePopupTimer = setTimeout(function () {
    if (!isCameraHovered.value) {
      isVisiblePopup.value = false;
      emit('mouseleave');
    }
  }, 300);
}

function onClickCameraElement(camera: ApiCameraWithIncludes) {
  isVisiblePopup.value = false;
  emit('click', camera);
}

function startDrag(evt: DragEvent, camera: ApiCameraWithIncludes) {
  isCameraHovered.value = false;
  isVisiblePopup.value = false;

  if (evt.dataTransfer) {
    const img = new Image();
    const cameraString = JSON.stringify(camera);

    evt.dataTransfer.setData("camera", cameraString);
    evt.dataTransfer.setDragImage(img, 0, 0);
  }

  emit('dragstart', evt);
}

function setPositionOfPopup(cameraId: string) {
  const popupHalfWidth = 130;
  const popupHeight = 250;
  let leftMargin = 0;

  const popupElement: HTMLElement | null = document.querySelector(`.floor-plan__popup--${cameraId}`);
  const hoveredMarker = cameraMarkerRef.value;
  const markerPosition = hoveredMarker && hoveredMarker.getBoundingClientRect();
  const imageElementContainerPosition = props.imageElementContainer && props.imageElementContainer.getBoundingClientRect();

  if (!markerPosition || !imageElementContainerPosition || !popupElement) {
    return;
  }

  const relativeMarkerLeftPosition = markerPosition.left - imageElementContainerPosition.left;
  const relativeMarkerRightPosition = imageElementContainerPosition.right - markerPosition.right;
  const relativeMarkerTopPosition = markerPosition.top - imageElementContainerPosition.top;
  const noSpaceOnRight = relativeMarkerRightPosition < popupHalfWidth;
  const noSpaceOnLeft = relativeMarkerLeftPosition < popupHalfWidth;

  if (noSpaceOnRight) {
    leftMargin = relativeMarkerRightPosition - popupHalfWidth;
  }

  if (noSpaceOnLeft) {
    leftMargin = popupHalfWidth - relativeMarkerLeftPosition;
  }

  popupPositions.onBottom = relativeMarkerTopPosition < popupHeight;
  popupElement.style.left = `${leftMargin}px`;
}

function useEditCameraAzimuthRange() {
  const cameraMarkerEditCenter = ref();
  const isEditingRangeAzimuth = ref(false);
  const currentAzimuth = ref(0);
  const currentRangeInMeters = ref(0);

  function getCenter(element: HTMLElement) {
    const {left, top, width, height} = element.getBoundingClientRect();
    return {x: left + width / 2, y: top + height / 2}
  }

  function startEditAzimuthRange() {
    currentAzimuth.value = props.azimuth;
    currentRangeInMeters.value = props.rangeInMeters;
    isEditingRangeAzimuth.value = true;
    document.addEventListener('mousemove', changeAzimuthRange);
    document.addEventListener('mouseup', endEditAzimuthRange);
  }

  function changeAzimuthRange(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const {clientX, clientY} = e;
    const longitudeFloorplanDistanceInMeters = getLatitudeFloorplanDistanceInMeters()
    const arrowCenter = getCenter(cameraMarkerEditCenter.value);
    const angleRad = Math.atan2(clientY - arrowCenter.y, clientX - arrowCenter.x) + Math.PI / 2;
    let rangeInPixels = Math.sqrt(
      Math.pow(clientX - arrowCenter.x, 2) +
      Math.pow(clientY - arrowCenter.y, 2)
    )

    if (rangeInPixels < 15) {
      rangeInPixels = 15;
    }

    currentAzimuth.value = (((angleRad / Math.PI) * 180) + 360) % 360
    currentRangeInMeters.value = (rangeInPixels / props.imageElementHeight) * longitudeFloorplanDistanceInMeters;
  }

  function endEditAzimuthRange(e) {
    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener('mousemove', changeAzimuthRange);
    document.removeEventListener('mouseup', endEditAzimuthRange);
    emit('changeRangeAzimuth', {azimuth: currentAzimuth.value, rangeInMeters: currentRangeInMeters.value})
    isEditingRangeAzimuth.value = false;
  }

  return {startEditAzimuthRange, isEditingRangeAzimuth, currentRangeInMeters, currentAzimuth, cameraMarkerEditCenter}
}

function getLatitudeFloorplanDistanceInMeters() {
  const lat1 = props.floorTopLeftLatitude;
  const lat2 = props.floorBottomRightLatitude;
  const oneLatitudeInMeters = 111.32 * 1000;
  const distance = Math.abs(lat2 - lat1);
  return distance * oneLatitudeInMeters;
}

</script>
<style lang="scss" scoped>
@import "../../assets/styles/main";

.camera-marker {
  width: 12px;
  height: 12px;
  position: absolute;
  cursor: default;
  transform: translate(-50%, -50%);

  //  FOV
  &__fov {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    svg {
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    circle, path {
      fill: $accent;
      opacity: 0.5;
      transition: color, opacity .2s ease-in-out;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  // CENTER
  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    z-index: 4;
    cursor: pointer;
  }

  // EDIT SCALE
  &__edit-scale {
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4
  }

  &__edit-scale-center {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid $primary;
    background-color: $primaryWhite;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &__edit-scale-line {
    width: 2px;
    height: 10px;
    background-color: $primary;
    transform: translate(-50%, 0);
    margin: -2px 0;
  }

  &__edit-scale-end {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid $primary;
    background-color: $primaryWhite;
    transform: translate(-50%, -50%);
    z-index: 4;
    transition: 0.3s;

    &:hover {
      transform: translate(-50%, -50%) scale(3);
    }
  }

  // LABEL
  &__label {
    position: absolute;
    opacity: 80%;
    z-index: 3;
    cursor: pointer;

    &--right {
      top: 50%;
      left: 100%;
      padding-left: 10px;
      margin-left: 10px;
      transform: translate(0, -50%);
    }

    &--left {
      top: 50%;
      right: 100%;
      padding-right: 10px;
      margin-right: 10px;
      transform: translate(0, -50%);
    }

    &--top {
      bottom: 100%;
      padding-bottom: 10px;
      margin-bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &--bottom {
      top: 100%;
      padding-top: 10px;
      margin-top: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  
  // MODIFICATIONS
  &--online {
    .camera-marker {
      &__center {
        border: 2px solid $positiveLight;
        background-color: $positive;
      }
    }
  }
  
  &--offline {
    .camera-marker {
      &__center {
        border: 2px solid $negativeLight;
        background-color: $negative;
      }
    }
  }

  &-dragging {
    position: absolute;
    z-index: 9;
    pointer-events: none;

    * {
      pointer-events: none !important;
    }
    
    > * {
      visibility: hidden;
    }
    
    > .show-during-dragging {
      visibility: visible !important;
    }
  }

  &--hovered {
    z-index: 9;

    .camera-marker__fov {
      circle, path {
        fill: $accentDark;
        opacity: 90%;
      }
    }

    :deep .label-btn {
      background-color: $accentDark;
    }
  }

  &:active {
    .camera-marker__fov {
      circle, path {
        fill: $primaryLight;
      }
    }

    :deep .label-btn {
      background-color: $primaryLight;
    }
  }

  &--edit, &--edit:hover {
    z-index: 9;

    .camera-marker__fov {
      circle, path {
        fill: $positive;
      }
    }

    :deep .label-btn {
      background-color: $positive;
    }
  }

  &--edit:hover {
    .camera-marker__fov {
      circle, path {
        opacity: 90%;
      }
    }
  }
}

</style>
