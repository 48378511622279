<template>
  <div class="drag-n-drop">
    <div class="drag-n-drop__part--left">
      <div class="part__title">
        {{ leftTitle }}
      </div>
      <div class="part__area">
        <draggable
          class="area__list"
          :list="listValue"
          group="items"
        >
          <div
            v-for="element in listValue"
            :key="element.value"
            class="area__item"
          >
            {{ element.text }}
          </div>
        </draggable>
      </div>
      <button-common
        type="clear"
        color="primary"
        @click="moveRight()"
      >
        <div>
          {{ addAllText }}
          <v-icon
            size="24"
            color="primary"
          >
            $icon_arrow_right
          </v-icon>
        </div>
      </button-common>
    </div>
    <div class="drag-n-drop__part--right">
      <div class="part__title">
        {{ rightTitle }}
      </div>
      <div class="part__area">
        <draggable
          class="area__list"
          :list="valueList"
          group="items"
          @change="change"
        >
          <div
            v-for="element in valueList"
            :key="element.value"
            class="area__item"
          >
            {{ element.text }}
          </div>
        </draggable>
      </div>
      <button-common
        type="clear"
        color="primary"
        class="area__move-button--right"
        @click="moveLeft()"
      >
        <div>
          <v-icon
            size="24"
            color="primary"
          >
            $icon_arrow_left
          </v-icon>
          {{ removeAllText }}
        </div>
      </button-common>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonCommon from '../buttons/ButtonCommon.vue';
import { ref } from 'vue';
import draggable from "vuedraggable";

type ArrayValue = {
  text: '';
  value: '';
}

const props = defineProps<{
  list: ArrayValue[],
  value: ArrayValue[],
  rightTitle: string;
  leftTitle: string;
  addAllText: string;
  removeAllText: string;
}>();

const emit = defineEmits<{
  (e: "input", value: ArrayValue[]): void;
}>();

const listValue = ref(props.list);
const valueList = ref(props.value);

function change() {
  emit('input', valueList.value);
}

function moveRight() {
  valueList.value = [ ...valueList.value, ...listValue.value ];
  listValue.value = [];
  change();
}

function moveLeft() {
  listValue.value = [ ...listValue.value, ...valueList.value ];
  valueList.value = [];
  change();
}

</script>

<style lang="scss">
@import "../../assets/styles/main";

.drag-n-drop {
  max-width: 368px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  .area__move-button--right {
    margin-left: auto;
    display: block;
  }
  .part {
    &__title {
      color: $secondary;
      margin-bottom: 8px;
      @include body-2;
    }
    &__area {
      box-shadow: inset 0 0 0 1px $elements;
      background: $backgrounds;
      height: 236px;
      overflow: overlay;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
  .area {
    &__list {
      flex-grow: 1;
    }
    &__item {
      height: 22px;
      width: 165px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      line-height: 2 !important;
      padding: 0 12px;
      @include tooltips;
      color: $primaryMedium;
      border-radius: 40px;
      background: $primaryWhite;
      filter: drop-shadow(0px 2px 12px rgba(42, 52, 64, 0.08));
      cursor: pointer;
      user-select: none;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      &:hover {
        background: $accentClear;
      }
      &.sortable-chosen:not(.sortable-ghost) {
        background: $accent;
        color: $primaryWhite;
      }
    }

  }
}
</style>