<template>
  <div
    class="settings-page"
    :style="cssVars"
  >
    <div
      v-show="isActionsVisible"
      class="settings-page__header"
    >
      <v-tabs
        v-if="isActionsVisible"
        v-model="tabs"
        hide-slider
        class="settings-page__tabs"
      >
        <v-tab
          v-for="(tab, index) in tabNames"
          :key="index"
          class="settings-page__tabs__tab"
          :data-testid="tab"
          :ripple="false"
          active-class="settings-page__tabs__tab--active"
        >
          <span class="subtitle-2"> {{ tab }}</span>
        </v-tab>
        <span
          class="settings-page__tabs__buttons"
        >
          <slot name="headerButtons" />
        </span>
      </v-tabs>
    </div>
    <hr class="hr">
    <div class="settings-page__content">
      <v-tabs-items v-model="tabs">
        <slot name="content" />
      </v-tabs-items>
    </div>
    <div
      v-if="isActionsVisible"
      ref="footer"
      class="settings-page__footer"
    >
      <hr class="hr">
      <div class="settings-page__footer__wrapper">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
	name: 'SettingsPage'
}
</script>

<script setup lang="ts">
import { computed, ref } from "vue";

withDefaults(defineProps<{
  tabNames: Array<string>
  isActionsVisible?: boolean
}>(), {
  isActionsVisible: true
})

const tabs = ref();
const footer = ref<HTMLElement>();
const cssVars = computed(() => {
  if (!footer.value) return {}
  return {
    '--footer-height': `${footer.value.offsetHeight}px`
  }
});


</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";
.settings-page{
  .hr {
    width: calc(100% + 40px); 
    margin-left: -20px;
    height: 1px;
    border: none;
    background-color: $elements;
  }

  &__header{
    height: 60px;
    display: flex;
    align-items: center;
  }
  &__content{
    padding-bottom: var(--footer-height);
  }	
  &__footer {
    position: fixed;
    background-color: $primaryWhite;
    bottom: 0px;
    width: 100%;
    z-index: 8;
    &__wrapper {
      display: flex;
      gap: 12px;
      padding: 20px 0;
      height: 77px;
    }
  }
  &__tabs{
    &__buttons{
      position: fixed;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-tab {
      text-transform: unset;
      color: $accent;
      letter-spacing: 0;
      padding: 0;
    }
    &__tab{
      min-width: unset;
      &:hover::before {
        opacity: 0 !important;
      }

      span {
        padding: 4px 16px !important;

        &:hover {
          background: $accentClear;
          border-radius: 4px;
        }
      }

      &.settings-page__tabs__tab--active {
        & span {
          background: $accentClear !important;
          border-radius: 4px;
        }

        &:focus::before {
          opacity: 0;
        }
      }
    }
  }
}
</style>