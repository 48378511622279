<template>
  <div>
    <DatePicker
      :key="key"
      v-model="dateValue"
      confirm
      :range="range"
      :clearable="false"
      :confirm-text="doneText"
      :lang="lang"
      :popup-class="getPopupClass"
      :disabled-date="disabledDate"
      :append-to-body="appendToBody"
      @change="onChange"
      @pick="onPick"
      @clear="onClear"
      @open="onOpen"
      @input-error="onInputError"
      @close="onPanelClose"
      @confirm="onConfirm"
    >
      <template #input>
        <slot name="activator">
          <text-field
            v-model="defaultValue"
            right-icon="$icon_calendar"
            label=""
            :class="[
              {
                'datepicker-input-field': openState,
                'datepicker-input-field__dark': dark === true && !range,
              }
            ]"
          />
          <div
            v-if="!defaultValue.length || showPlaceholder"
            class="input-placeholder-text"
          >
            {{ placeholderText }}
          </div>
        </slot>
      </template>
      <template #footer>
        <button-common
          type="clear"
          :text="cancelText"
          color="depressed"
          @click="cancel"
        />
      </template>
      <template
        v-if="range === true && sideBar === true"
        #sidebar="{ emit }"
      >
        <div
          v-for="shortcut in getShortcuts"
          :key="shortcut.value"
        >
          <button
            :class="`mx-select-btn-text mx-btn mx-btn-text ${rangeType === shortcut.value ? 'active' : ''}`"
            @click="selectDate(shortcut.value, emit)"
          >
            {{ shortcut.text }}
          </button>
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script setup lang="ts">
import DatePicker from 'vue2-datepicker';
import { computed, ref, watch, nextTick } from 'vue'
import 'vue2-datepicker/index.css';
import TextField from '../text-inputs/TextField.vue';
import ButtonCommon from '../buttons/ButtonCommon.vue'

type Props = {
  range: boolean,
  sideBar?: boolean,
  monthNames: string[],
  weekDayNames: string[],
  sidebarOptions?: string[],
  cancelText: string,
  doneText: string,
  value: string | string[],
  placeholderText: string,
  disableFutureDates?: boolean,
  disablePastDates?: boolean,
  dark?: boolean,
  appendToBody?: boolean,
  startWithPlaceholder?: boolean,
}

const props =  withDefaults(defineProps<Props>(), {
  appendToBody: true,
});
const valueProp = computed(() => props.value as (string | string[]));
const openState = ref<boolean>(false);
const showPlaceholder = ref<boolean | undefined>(props.startWithPlaceholder);

const emit = defineEmits<{
  (e: "input", value: string | string[]): void;
  (e: "close"): void;
  (e: "open"): void;
}>();

const defaultValue = computed({
  get: () => {
    if (openState.value) {
      return displayText.value ? displayText.value : ''
    } else {
      return props.range ? format(new Date(valueProp.value[0])) + ' ~ ' + format(new Date(valueProp.value[1])) : format(new Date(valueProp.value as string));
    }
  },
  set: (newValue: string) => {
    displayText.value = newValue;
  }
});

const dateValue = computed({
  get: () => {
    if (props.range) {
      const dates = displayText.value.split('~');
      const startDate = isValidDate(dates[0]) ? new Date(dates[0]) : undefined;
      const endDate = isValidDate(dates[1]) ? new Date(dates[1]) : undefined;
      if (startDate && endDate) {
        return [startDate, endDate];
      } else if (startDate) {
        return [startDate, startDate];
      }
    }
    return new Date(displayText.value);
  },
  set: (newValue: Date | Date[]) => {
    if (Array.isArray(newValue) && newValue[0] !== null && newValue[1] !== null) {
      displayText.value = format(newValue[0]) + ' ~ ' + format(newValue[1]);
    } else if (!Array.isArray(newValue) && newValue !== null) {
      displayText.value = format(newValue);
    }
  }
});

const rangeDiff = ref<number | null>(null);
const pickStartDate = ref<Date | null>(null);
const pickEndDate = ref<Date | null>(null);
const rangeType = ref<string | null>(null);
const key = ref<number>(0);
const error = ref<boolean>(false);
const displayText = ref<string>('');
const intermediateDate = ref<string | string[]>();

const lang = computed(() => {
  return {
    formatLocale: {
      firstDayOfWeek: 1,
      months: props.monthNames,
      weekdaysMin: props.weekDayNames,
    },
    monthFormat: 'MMMM',
  }
}
);

const getPopupClass = computed(() => {
  const className = [];
  if (props.range && rangeDiff.value) {
    switch (rangeDiff.value) {
      case 0:
        className.push('rangeDifferenceZeroClass');
        break;
      case 1:
        className.push('rangeDifferenceOneClass');
        break;
      default:
        className.push('rangeDifferenceClass');
    }
  } else {
    className.push('mx-datepicker-popup-range');
  }

  if (props.dark !== undefined && props.dark === true && !props.range) {
    className.push('mx-datepicker-popup__dark');
  }

  if (!pickEndDate.value && pickStartDate.value !== null) {
    className.push('remove-radius');
  }
  if (pickEndDate.value != null && pickEndDate.value.getDay() == 1) {
    nextTick(() => {
      document.getElementsByClassName("cell active")[1].classList.add("remove-end-radius");
    });
  }
  return className;
});


const getShortcuts = computed(() => {
  return [
    {
      text: props.sidebarOptions[0],
      value: 'today'
    },
    {
      text: props.sidebarOptions[1],
      value: 'yesterday'
    },
    {
      text: props.sidebarOptions[2],
      value: 'thisWeek'
    },
    {
      text: props.sidebarOptions[3],
      value: 'lastWeek'
    },
    {
      text: props.sidebarOptions[4],
      value: 'last2Week'
    },
    {
      text: props.sidebarOptions[5],
      value: 'thisMonth'
    },
    {
      text: props.sidebarOptions[6],
      value: 'lastMonth'
    },
    {
      text: props.sidebarOptions[7],
      value: 'custom'
    },
  ];
});

function onClear() {
  rangeType.value = null;
}

function onOpen() {
  openState.value = true;
  error.value = false;
  intermediateDate.value = valueProp.value;
  emit("open");

  if (!Array.isArray(valueProp.value)) {
    rangeType.value = null;
    displayText.value = format(new Date(valueProp.value));
  } else if (valueProp.value[0] && valueProp.value[1]) {
    selectRangeType(new Date(valueProp.value[0]), new Date(valueProp.value[1]));
    displayText.value = format(new Date(valueProp.value[0])) + ' ~ ' + format(new Date(valueProp.value[1]));
  }
}

function onInputError(text: string) {
  error.value = true
  key.value++;
}

function onChange() {
  pickStartDate.value = null;
  pickEndDate.value = null;
  showPlaceholder.value = false;
}

function onPick(val: Date) {
  if (!props.range) {
    displayText.value = format(val);
  }

  if (pickStartDate.value && pickEndDate.value) {
    pickStartDate.value = null;
    pickEndDate.value = null;
    rangeType.value = null;
  }

  if (!pickStartDate.value) {
    pickStartDate.value = val;
    rangeType.value = null;
    if (props.range) {
      displayText.value = format(pickStartDate.value);
    }
    return;
  }
  if (!pickEndDate.value) {
    pickEndDate.value = val;
  }

  const today = new Date();
  if (pickStartDate.value.getDate() === pickEndDate.value.getDate()) {
    const yesterday = new Date(Date.now() - 24 * 3600 * 1000);
    if (pickStartDate.value.getDate() === today.getDate()) {
      rangeType.value = 'today';
      rangeDiff.value = 0;
      displayText.value = `${format(pickStartDate.value)} ~ ${format(pickEndDate.value)}`;
      return;
    } else if (pickStartDate.value.getDate() === yesterday.getDate()) {
      displayText.value = `${format(pickStartDate.value)} ~ ${format(pickEndDate.value)}`;
      rangeType.value = 'yesterday';
      rangeDiff.value = 0;
      return;
    }
  }

  if (pickStartDate.value > pickEndDate.value) {
    const tempDate = pickStartDate.value;
    pickStartDate.value = pickEndDate.value;
    pickEndDate.value = tempDate;
  }

  if (props.range) {
    displayText.value = `${format(pickStartDate.value)} ~ ${format(pickEndDate.value)}`;
  }

  selectRangeType(pickStartDate.value, pickEndDate.value);
}

function selectRangeType(startDate: Date, endDate: Date) {
  const diff = startDate.getDate() - endDate.getDate();
  rangeDiff.value = diff < 0 ? diff * -1 : diff;

  if (rangeDiff.value === 0) {
    const today = new Date();
    const yesterday = new Date(Date.now() - 24 * 3600 * 1000);
    if (startDate.getDate() === today.getDate()) {
      rangeType.value = 'today';
    } else if (startDate.getDate() === yesterday.getDate()) {
      rangeType.value = 'yesterday';
    }
    return;
  }

  const thisWeek = getThisWeekDate();
  if (startDate.getDate() === thisWeek.first.getDate() && endDate.getDate() === thisWeek.end.getDate()) {
    rangeType.value = 'thisWeek';
    return;
  }

  const lastWeekDate = getLastWeekDate('lastWeek');
  if (startDate.getDate() === lastWeekDate.first.getDate() && endDate.getDate() === lastWeekDate.end.getDate()) {
    rangeType.value = 'lastWeek';
    return;
  }

  const last2WeekDate = getLastWeekDate('last2Week');
  if (startDate.getDate() === last2WeekDate.first.getDate() && endDate.getDate() === last2WeekDate.end.getDate()) {
    rangeType.value = 'last2Week';
    return;
  }

  const lastMonthDate = getMonthDate('lastMonth');
  if (startDate.getDate() === lastMonthDate.first.getDate() && endDate.getDate() === lastMonthDate.end.getDate()) {
    rangeType.value = 'lastMonth';
    return;
  }

  const thisMonthDate = getMonthDate('thisMonth');
  if (startDate.getDate() === thisMonthDate.first.getDate() && endDate.getDate() === thisMonthDate.end.getDate()) {
    rangeType.value = 'thisMonth';
    return;
  }

  rangeType.value = 'custom';
}

function getMonthDate(type: string) {
  const first = new Date((new Date()).getFullYear(), (new Date()).getMonth() - ((type === 'lastMonth') ? 1 : 0), 1);
  const end = (type === 'lastMonth') ? new Date((new Date()).getFullYear(), (new Date()).getMonth(), 0) : new Date();
  return { first, end };
}

function getLastWeekDate(type: string) {
  const today = new Date();
  const diff = (type === 'last2Week') ? 13 : 6;
  const first = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - diff);
  const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
  return { first, end };
}

function getThisWeekDate() {
  const today = new Date();
  const first = new Date(today.setDate(today.getDate() - today.getDay() + 1));
  const end = new Date();
  return { first, end };
}

function selectDate(type: string, emit: any) {
  let isCustom = false;
  if (type === "custom") {
    isCustom = true;
    type = !rangeType.value ? "today" : rangeType.value;
  }
  switch (type) {
    case 'today': selectToday(emit); break;
    case 'yesterday': selectYesterday(emit); break;
    case 'lastWeek': selectLastWeek('lastWeek', emit); break;
    case 'last2Week': selectLastWeek('last2Week', emit); break;
    case 'thisWeek': selectThisWeek(emit); break;
    case 'thisMonth': selectMonth('thisMonth', emit); break;
    case 'lastMonth': selectMonth('lastMonth', emit); break;
  }
  if (isCustom) {
    rangeType.value = "custom";
  }
}

function selectToday(emit: any) {
  const startDate = new Date();
  displayText.value = format([startDate, startDate]);
  emit([startDate, startDate]);
  rangeDiff.value = 0;
  rangeType.value = 'today';
}

function selectYesterday(emit: any) {
  const startDate = new Date(Date.now() - 24 * 3600 * 1000);
  displayText.value = format([startDate, startDate]);
  emit([startDate, startDate]);
  rangeDiff.value = 0;
  rangeType.value = 'yesterday';
}

function selectLastWeek(type: string, emit: any) {
  const vDate = getLastWeekDate(type);
  displayText.value = format([vDate.first, vDate.end]);
  emit([vDate.first, vDate.end]);
  rangeDiff.value = 7;
  rangeType.value = type;
}

function selectThisWeek(emit: any) {
  const vDate = getThisWeekDate();
  rangeDiff.value = vDate.end.getDate() - vDate.first.getDate();
  displayText.value = format([vDate.first, vDate.end]);
  emit([vDate.first, vDate.end]);
  rangeType.value = 'thisWeek';
}

function selectMonth(type: string, emit: any) {
  const vDate = getMonthDate(type);
  displayText.value = format([vDate.first, vDate.end]);
  emit([vDate.first, vDate.end]);
  rangeDiff.value = vDate.end.getDate() - vDate.first.getDate();
  rangeType.value = type;
}

function cancel() {
  if (!dateValue.value || dateValue.value.toString() === ',') {
    rangeType.value = null;
  }

  if (Array.isArray(intermediateDate.value)) {
    displayText.value = intermediateDate.value[0] + ' ~ ' + intermediateDate.value[1];
  } else {
    displayText.value = intermediateDate.value;
  }
  openState.value = false;
  key.value++;
}

function onPanelClose() {
  openState.value = false;
  emit("close");
}

function onConfirm(val: Date) {
  if (!intermediateDate.value) {
    return;
  }

  if (!displayText.value.length) {
    emit("input", intermediateDate.value);
    return;
  }

  if (props.range) {
    const dates = displayText.value.split('~');
    if (!dates.length) {
      emit("input", intermediateDate.value);
      return;
    }
    const startDate = isValidDate(dates[0]) ? dates[0].trim() : undefined;
    const endDate = isValidDate(dates[1]) ? dates[1].trim() : undefined;

    if (startDate && endDate) {
      const startDateVal = new Date(startDate);
      const endDateVal = new Date(endDate);
      if (startDateVal > endDateVal || endDateVal.getTime() > (new Date()).getTime()) {
        emit("input", intermediateDate.value);
      } else {
        emit("input", dates);
      }
    } else {
      emit("input", intermediateDate.value);
    }
  } else {
    const isValid = isValidDate(displayText.value);
    if (isValid) {
      emit("input", displayText.value);
    } else {
      emit("input", intermediateDate.value);
    }
  }
  openState.value = false;
}

function format(val: Date | Date[]) {
  if (Array.isArray(val)) {
    val[0].setHours(12, 0, 0, 0)
    val[1].setHours(12, 0, 0, 0)
    return `${val[0].toISOString().slice(0, 10)} ~ ${val[1].toISOString().slice(0, 10)}`;
  }
  val.setHours(12, 0, 0, 0)
  return val.toISOString().slice(0, 10);
}

watch(
  () => displayText.value,
  () => {
    if (displayText.value === undefined) {
      return;
    }
    const len = displayText.value.length;

    if (props.range) {
      if ([4, 7, 17, 20].includes(len)) {
        displayText.value += "-";
      } else if (len === 11) {
        displayText.value += "~";
      }
    } else {
      if ([4, 7].includes(len)) {
        displayText.value += "-";
      }
    }
    updateRange(displayText.value);
  }
);

function updateRange(val: string) {
  if (props.range) {
    const dates = val.split('~');
    const startDate = isValidDate(dates[0]) ? dates[0] : undefined;
    const endDate = isValidDate(dates[1]) ? dates[1] : undefined;
    if (startDate && endDate) {
      const startDateVal = new Date(startDate)
      const endDateVal = new Date(endDate)
      if (startDateVal.getDate() > endDateVal.getDate()) {
        return;
      } else {
        selectRangeType(startDateVal, endDateVal);
        return;
      }
    }
  }
}

function isValidDate(val: string) {
  if (!val) {
    return false;
  }
  return !isNaN(Date.parse(val.trim())) && val.trim().length === 10;
}

function disabledDate(val: Date) {
  if(!props.disableFutureDates && !props.disablePastDates) {
    return false;
  }
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  val.setHours(0, 0, 0, 0);

  if (props.disableFutureDates && val > today) {
    return true;
  }

  if (props.disablePastDates && val < today) {
    return true;
  }
  return false;  
}
</script>

<style lang="scss">
@import "../../assets/styles/main";

@mixin arrow-btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background: $elements;
  }

  &:active {
    background: $secondaryLight;
  }

  &:focus-visible {
    border: 1px solid $primary;
  }
}

@mixin arrow-btn-dark {
  &:hover {
    background: $primaryLight;
  }

  &:active {
    background: $secondaryMedium;
  }

  &:focus-visible {
    background: none;
    border: 1px solid $primaryWhite;
  }
}

@mixin calender-cell {
  .mx-date-row {
    .cell {
      &.active {
        background: $accent !important;
        color: $primaryWhite !important;
        position: relative;

        &::before {
          background: $accentLight;
          position: absolute;
          z-index: -1;
          pointer-events: none;
          content: '';
          display: inline-block;
          width: 33px;
          height: 32px;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.rangeDifferenceClass {
  @include calender-cell;
}

.rangeDifferenceZeroClass {
  @include calender-cell;

  .mx-date-row {
    .cell {
      &.active {
        &::before {
          border-radius: 33px !important;
          background: $accentLight !important;
        }
      }
    }
  }
}

.rangeDifferenceOneClass {
  @include calender-cell;

  .mx-date-row {
    .cell {
      &.active {
        &::before {
          background: $accentLight !important;
          border-radius: 33px 0 0 33px !important;
        }

        &+td {
          &::before {
            border-radius: 0 33px 33px 0 !important;
            background: $accentLight !important;
          }
        }
      }
    }
  }
}

.mx-datepicker-popup-range {
  .mx-calendar {
    width: 230px !important;
  }
}

.mx-datepicker-popup {
  margin-top: 4px !important;
  background-color: $backgrounds !important;
  border-radius: 4px;
  border: 1px solid $elements;
  box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08);
  padding: 4px 0 4px 0px;
  font-family: Roboto, sans-serif;
  transform: none;


  .mx-icon-right:before,
  .mx-icon-left:before {
    border-width: 3px 0 0 3px;
    border-radius: 0;
  }

  .mx-datepicker-sidebar {
    width: 194px !important;
    padding: 4px 4px 4px 0;
    margin-left: 4px;
    height: 271px;
    border-bottom: 1px solid $elements;
    border-right: 1px solid $elements;

    +.mx-datepicker-content {
      margin-left: 194px !important;
      border-left: none;
    }

    .active {
      background: $accent;
      color: $primaryWhite !important;

      &:hover {
        background: $accent !important;
      }
    }

    .mx-select-btn-text {
      padding: 4px 8px !important;
      width: 100%;
      @include body-2;
      border-radius: 4px;

      &:hover {
        background: $accentClear;
      }

      &:active {
        background: $secondaryLight;
      }

      &:focus-visible {
        border: 1px solid $primary;
      }
    }
  }

  .mx-calendar {
    width: 233px;
  }

  .mx-datepicker-footer {
    display: flex;
    align-items: baseline;
    justify-content: end;
    border-top: 1px solid $elements;
    margin: 0 4px;
    padding: 6px 0;

    .mx-datepicker-btn-confirm {
      padding: 8px 16px;
      border-radius: 4px;

      &:hover {
        background: $accentClear;
      }

      &:focus-visible {
        background: none;
        border: 1px solid $primary;
      }

      &:active {
        background: $accentLight;
      }
    }
  }

  .mx-btn-text {
    @include subtitle-2;
    color: $primary;
  }

  .mx-calendar-panel-month,
  .mx-calendar-panel-year {
    padding: 8px 4px 4px 4px !important;

    .mx-calendar-header-label {
      pointer-events: none;
    }

    .mx-calendar-header {
      color: $primary;
      @include subtitle-2;
      text-align: center;
    }

    .mx-btn-icon-double-left,
    .mx-btn-icon-double-right {
      padding: 0;
    }

    .mx-icon-double-right {
      @include arrow-btn;
      padding-right: 2px;
      padding-top: 1px;

      &:before {
        left: 6px;
        border-width: 3px 0 0 3px;
        border-radius: 0;
      }

      &:after {
        left: -4px;
      }
    }

    .mx-icon-double-left {
      @include arrow-btn;
      padding-top: 1px;

      &:before {
        left: 5px;
        border-width: 3px 0 0 3px;
        border-radius: 0
      }

      &:after {
        left: -5px;
      }
    }

    .mx-calendar-header-label {
      button {
        margin-top: 4px;
      }
    }

    .mx-table-year,
    .mx-table-month {
      -webkit-border-horizontal-spacing: 12px;
      -webkit-border-vertical-spacing: 23px;
      @include body-2;
      text-align: center;
      color: $primary;

      .cell {
        &:hover {
          color: $primary;
          background: $accentClear;
          border-radius: 4px;
        }
      }

      .active {
        background: $accent !important;
        border-radius: 4px;

        &:hover {
          color: $primaryWhite;
        }
      }
    }

    .mx-table-month {
      width: 180px;
      margin: auto;
      border-spacing: 0;
      -webkit-border-vertical-spacing: 23px;

    }

    .mx-table-year {
      width: 180px;
      margin: auto;
      border-spacing: 0;
      -webkit-border-vertical-spacing: 14px;
      -webkit-border-horizontal-spacing: 20px;
    }

  }

  .mx-calendar-panel-date {
    padding: 8px 4px 4px 4px !important;

    .mx-icon-double-right,
    .mx-icon-double-left {
      display: none;
    }

    .mx-btn-current-year {
      pointer-events: none;
    }

    .mx-btn-current-month {
      margin-top: 4px;
    }

    .mx-btn-icon-left,
    .mx-btn-icon-right {
      @include arrow-btn;
    }

    .mx-btn-text:hover {
      color: $primary;
    }

    .mx-calendar-header {
      color: $primary;
      @include subtitle-2;
      text-align: center;
    }


  }

  .mx-datepicker-footer .mx-btn {
    border: none;
    @include subtitle-2;
    color: $accent;
  }

  .mx-table {
    width: 222px;
  }

  .mx-table-date th {
    color: $secondaryMedium;
    @include subtitle-2;
    text-align: center;
  }

  .mx-date-row {
    .cell {
      @include body-2;
      color: $primary;
      border-radius: 50%;
      text-align: center;

      &.not-current-month {
        background: unset !important;
        pointer-events: none;
        color: $secondaryMedium !important;
      }


      &.today {
        @include subtitle-2;
        text-align: center;
        color: $accent;
      }

      &.active {
        color: $primaryWhite;
        background-color: $accent;

        &:hover {
          background-color: $accent;
          color: $primaryWhite;
        }

        &::before {
          border-radius: 33px 0 0 33px;
          background: $accentLight;
        }
      }

      &:hover {
        background: $accentLight;
        color: $primary;
      }

      &.in-range {
        background: $accentLight;
        color: $primary;
        border-radius: 0;
      }

      &.hover-in-range {
        background: none;
        border-radius: 0;
        border-top: 1px dashed $elements;
        border-bottom: 1px dashed $elements;
        color: $primary;
      }

      &.disabled {
        background: unset;
        color: $secondaryLight;
        cursor: auto;
      }
    }

    .in-range~.active {
      &::before {
        border-radius: 0 33px 33px 0;
        background: $accentLight;
      }
    }
  }

  &__dark {
    background-color: $primary !important;
    border: 1px solid $primaryMedium !important;
    box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08);

    .mx-calendar-panel-date {
      .mx-btn-text:hover {
        color: $primaryWhite;
      }

      .mx-calendar-header {
        * {
          color: $primaryWhite;
        }

        .mx-btn-icon-left,
        .mx-btn-icon-right {
          @include arrow-btn-dark;
        }
      }

      .mx-table-date {
        .not-current-month {
          color: $secondary !important;
        }

        .cell {
          color: $primaryWhite;

          &.today:not(.active) {
            color: $accent;

            &:active {
              color: $primaryWhite;
            }
          }

          &.disabled {
            color: $secondary;
            pointer-events: none;
          }

          &:hover:not(.disabled) {
            background: $accentLight;
            color: $primary;
          }

          &:active {
            background: $accent !important;
          }

          &.active {
            background: $accentDark !important;

            &:hover {
              background: $accentDark !important;
              color: $primaryWhite;
            }
          }
        }
      }
    }

    .mx-calendar-panel-month {
      .mx-calendar-header {
        * {
          color: $primaryWhite;
        }

        .mx-icon-double-right,
        .mx-icon-double-left {
          @include arrow-btn-dark;
        }
      }

      .mx-table-month {
        .cell {
          color: $primaryWhite;

          &:hover {
            background: $accentDark;
            border-radius: 4px;
            color: $primaryWhite;
          }

          &.active:hover {
            background: $accent;
          }
        }
      }
    }

    .mx-datepicker-footer {
      border-color: $primaryMedium;

      .clearBtn {
        color: $primaryWhite;

        &:hover {
          background: $primaryLight !important;
        }

        &:focus-visible {
          background: none;
          border: 1px solid $primaryWhite;
        }

        &:active {
          background: $secondaryMedium !important;
        }
      }

      .mx-datepicker-btn-confirm {
        &:hover {
          background: $primaryLight;
        }

        &:focus-visible {
          background: none;
          border: 1px solid $accent;
        }

        &:active {
          background: $accentLight;
        }
      }
    }
  }
}

.mx-datepicker {
  width: 232px;

  .mx-input-wrapper {
    .datepicker-input-field {
      .v-input__slot {
        & fieldset {
          color: $accent !important;
          border: 1px solid $accent !important;
        }
      }

      &__dark {
        .v-input .v-input__slot {
          fieldset {
            background: unset;
            backdrop-filter: unset;
            border: unset;
          }

          &:focus-within {
            & fieldset {
              color: $accent !important;
              border: 1px solid $accent !important;
            }
          }

          input,
          svg {
            color: $primaryWhite !important;
          }
          background: rgba(33, 42, 52, 0.72);
          backdrop-filter: blur(2px);
        }
      }
    }
  }

  .input-placeholder-text {
    position: absolute;
    top: 8px;
    z-index: 2;
    color: $secondaryMedium;
    @include body-2;
    padding-left: 10px;
    margin-left: 2px;
    background-color: $backgrounds;
  }

}

.remove-radius {
  .rangeDifferenceOneClass .mx-date-row .cell.active::before {
    border-radius: 33px !important;
  }

  .mx-date-row .cell.active::before {
    border-radius: 33px !important;
  }
}

.remove-end-radius::before {
  border-radius: 0 33px 33px 0 !important;
}
</style>

