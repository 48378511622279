<template>
  <div>
    <v-data-table
      :key="key"
      class="eewc-table"
      :class="denseRows ? 'eewc-table--dense' : ''"
      hide-default-header
      hide-default-footer
      item-key="name"
      :page.sync="page"
      :headers="tableHeaders"
      :items="tableData"
      :items-per-page="itemsPerPage"
      :server-items-length="serverItemsLength"
      :search="search"
      @current-items="displayedItems = $event"
      @page-count="pageCount = $event"
      @pagination="updatePaginationData"
      @update:options="(value) => emit('options', value)"
    >
      <template #header="{ props, on }">
        <thead>
          <div :class="`header-selector ${(selectedRows.length > 0) ? 'header-selector--block' : ''}`">
            <check-box
              v-if="selectable"
              :value="tableData.every(td => selectedRows.includes(td.id)) && (selectedRows.length > 0)"
              :indeterminate="!tableData.every(td => selectedRows.includes(td.id)) && tableData.some(td => selectedRows.includes(td.id))"
              @change="selectAll"
            />
          </div>
          <tr>
            <th
              v-for="(header, index) in props.headers"
              :key="index"
              :class="{'eewc-table__header--sortable': header.sortable}"
            >
              <div v-if="loading && header.text">
                <skeleton
                  class="header-skeleton"
                  :class="header.justify"
                  type="text"
                  :max-width="header.skeletonWidth || '124px'"
                />
              </div>
              <a
                v-else
                class="subtitle-2 d-flex align-center"
                :class="
                  [
                    header.justify && 'justify-' + header.justify,
                    {
                      'table-header--center-text-sortable': (header.justify === 'center') && header.sortable,
                    }
                  ]
                "
                @click="header.sortable && on.sort(header.value)"
              >
                <tooltipped-text :text="header.text" />
                <v-icon
                  v-if="(header.value === props.options.sortBy[0]) && header.sortable"
                  size="12.5"
                  :class="(props.options.sortDesc[0] ? 'rotate180' : '') + ' ml-2'"
                  color="primary"
                >
                  $icon_sort
                </v-icon>
                <v-icon
                  v-else-if="header.sortable"
                  size="12.5"
                  class="table-header__hover-sort-icon ml-2"
                  color="secondaryMedium"
                >
                  $icon_sort
                </v-icon>
              </a>
            </th>
          </tr>
        </thead>
      </template>
      <template #item="{ item }">
        <tr v-if="loading">
          <slot name="skeletonRow" />
          <td
            v-if="hasActions"
            class="table__table-cell--last-cell"
          >
            <skeleton type="icon" />
          </td>
        </tr>

        <!-- This is for the case where the row slot is sent as a tr (of group of tr's), not just td's -->
        <!-- <slot v-else-if="isDataTr" name="row" :item="{ item, expand, isExpanded }"/> -->

        <slot
          v-else-if="item.isChild && expanded.includes(item[parentIdPropertyName])"
          name="expandedRow"
          :item="item"
          :select-row="selectRow"
        />

        <tr
          v-else-if="!item.isChild"
          :class="[
            {
              'expanded-row--color': (expanded.includes(item.id) || selectedRows.includes(item.id)),
              'selected': selectedRows.includes(item.id),
              'table-row-hover' : hoverRowId === item.id,
              'table-row--selected': selectedRow === item.id
            }
          ]"
          @click="item.children ? expandChildren(item) : $emit('row-click', item)"
        >
          <!-- row slot is consisting of td's -->
          <slot
            name="row"
            :item="item"
            :is-expanded="expanded.includes(item.id)"
            :select-row="selectRow"
          />
          <td
            v-if="item.actions"
            class="table__table-cell--last-cell"
            @click.stop
          >
            <drop-down-menu
              :tooltip="moreTooltip"
              class="pa-2"
              :data-testid="'table-row-action-button-'+item.id"
              :menu-items="item.actions"
              :icon-size="20"
              @select="value => emitSelect(value, item)"
              @menuCollapsed="value => selectRow(value, item.id)"
            />
          </td>
        </tr>

        <div
          v-if="selectable && item?.isItemSelectable !== false"
          class="row-selector"
          @mouseover="onCheckBoxHover(item.id)"
          @mouseleave="onCheckBoxHover(null)"
        >
          <check-box
            :data-testid="'table-row-check-box-'+item.id"
            :value="selectedRows.includes(item.id)"
            @change="() => selectRowItem(item.id)"
          />
        </div>
      </template>
      <!-- <template #expanded-item="{ item }">
        <slot name="expandedRow" :item="item" :selectRow="selectRow" />
      </template> -->
      <template
        v-if="!loading && (props.serverItemsLength || tableData.length) > itemsPerPageOptions[0]"
        #footer
      >
        <div class="table-footer d-flex">
          <div class="table-footer__page-size d-flex align-baseline">
            <span class="subtitle-2 pa-2 pr-0"> Page size: </span>
            <sort-drop-down
              v-model="itemsPerPageOption"
              :items="itemsPerPageMenuOptions"
              append-text=""
              @input="value => selectEvent(value)"
            />
          </div>
          <div
            v-if="pageCount > 1"
            class="table-footer__pagination ml-auto d-flex align-center subtitle-2"
          >
            <div class="mr-10">
              {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
            </div>
            <v-btn
              icon
              :ripple="false"
              :disabled="page === 1"
              text
              class="table-footer__page-arrow"
              @click="page = prevPage"
            >
              <tooltip :text="page !== 1 ? previousTooltip : ''">
                <template #content="{on, attrs}">
                  <v-icon
                    size="15"
                    v-bind="attrs"
                    :color="page !== 1 ? 'primary' : ''"
                    v-on="on"
                  >
                    $icon_arrow_left
                  </v-icon>
                </template>
              </tooltip>
            </v-btn>
            <v-btn
              icon
              :ripple="false"
              :disabled="page === pageCount"
              text
              class="table-footer__page-arrow"
              @click="page = nextPage"
            >
              <tooltip :text="nextTooltip">
                <template #content="{on, attrs}">
                  <v-icon
                    size="15"
                    v-bind="attrs"
                    :color="page !== pageCount ? 'primary' : ''"
                    v-on="on"
                  >
                    $icon_arrow_right
                  </v-icon>
                </template>
              </tooltip>
            </v-btn>
          </div>
        </div>
      </template>
      <!-- For empty state -->
      <template #no-data>
        <slot name="noData" />
      </template>
      <template
        v-if="displayedItems.length === 0 && !loading && search && $slots.noResult" 
        #body
      >
        <slot name="noResult" />
      </template>
    </v-data-table>
  </div>
</template>

<script setup lang="ts">
import Skeleton from './loaders/Skeleton.vue'
import DropDownMenu from './dropdowns/DropDownMenu.vue'
import SortDropDown from './dropdowns/SortDropDown.vue'
import TooltippedText from './tooltip/TooltippedText/TooltippedText.vue'
import Tooltip from './tooltip/Tooltip.vue'
import CheckBox from './selection-controls/CheckBox.vue'
import { computed, onBeforeUpdate, ref, watch } from 'vue'
import vuetify from '../service/useVuetify';

type Item = {
  id: number,
  children?: [],
  isChild?: boolean,
  isItemSelectable?: boolean,
}

type PageMenuItem = {
  text: string,
  value: string,
}

type Pagination = {
  pageStart: number;
  pageStop: number;
  itemsLength: number;
  page: number;
  pageCount: number;
}

type Option = {
  page: number,
  itemsPerPage: number,
  sortBy: string[],
  sortDesc: boolean[],
  groupBy: string[],
  groupDesc: boolean[],
  mustSort: boolean,
  multiSort: boolean,
}

const props = withDefaults(
  defineProps<{
    data?: Array<Item>,
    loading?: boolean,
    headers?: Array<{
      skeletonWidth?: string,
      justify?: string,
      text?: string,
      value?: string,
      sortable?: boolean,
    }>,
    hasActions?: boolean,
    previousTooltip?: string,
    nextTooltip?: string,
    selectable?: boolean,
    selectedRows?: Array<any>,
    moreTooltip?: string,
    parentIdPropertyName?: string,
    itemsPerPage?: number,
    serverItemsLength?: number
    search?: string,
    expandAllRows?: boolean,
  }>(),
  {
    data: () => [],
    headers: () => [],
    previousTooltip: '',
    nextTooltip: '',
    selectable: false,
    selectedRows: () => [],
    moreTooltip: '',
    parentIdPropertyName: '',
    itemsPerPage: 25,
    expandAllRows: false,
  }
);

const page = ref(1);
const refs = ref<string[]>([]);
const pageCount = ref<number>(0);
const itemsPerPage = ref(props.itemsPerPage);
const itemsPerPageOption = ref<PageMenuItem>({text: itemsPerPage.value.toString(), value: itemsPerPage.value.toString()});
const itemsPerPageOptions = ref([25, 50, 100]);
const pageStart = ref<number | null>(null);
const pageStop = ref<number | null>(null);
const itemsLength = ref<number | null>(null);
const selectedRow = ref<number | null>(null);
const hoverRowId = ref<number | null>(null);
const key = ref(0);
const expanded = ref<number[]>([]);
const displayedItems = ref([]);

const emit = defineEmits<{
  (e: string, item?: Item): void;
  (e: 'row-select', item: number | number[]): void;
  (e: 'pagination', pagination: Pagination): void;
  (e: 'options', option: Option): void;
}>();

watch(
  () => props.expandAllRows,
  () => {
    props.expandAllRows ? props.data.forEach((item) => !item.isChild && expanded.value.push(item.id)) : (expanded.value = []);
  }
);

const prevPage = computed(() => {
  const result = page.value - 1
  if (result < 1) return 1
  else return result
})
const nextPage = computed(() => {
  const result = page.value + 1
  if (result > pageCount.value!) return result - 1
  else return result
})
const denseRows = computed(() => { //rows are dense on larger breakpoints
  return vuetify.value.breakpoint.name === "xl"
})
const itemsPerPageMenuOptions = computed(() => {
  const result = itemsPerPageOptions.value.map(item => {
    return { text: item.toString(), value: item.toString() }
  })
  const itemsLength = props.serverItemsLength || tableData.value.length;

  if (itemsLength < parseInt(result.at(-2)!.value)) result.pop()
  return result
})
const tableData = computed(() => {
  return props.data.filter(da => (expanded.value.includes((da as any)[props.parentIdPropertyName]) && da.isChild) || !da.isChild);
})
const tableHeaders = computed(() => {
  let headers = props.headers;
  if(props.hasActions) {
    headers = props.headers.concat({ sortable: false })
  }
  return headers
})

function onCheckBoxHover(rowId: number | null) {
  hoverRowId.value = rowId;
}
onBeforeUpdate(() => {
  refs.value = [];
})
function selectRow(value: boolean, id: number, rowProp = null) { //row becomes grey when having its menu open
  // rowProp could be sent if this method is used in the exapanded items
  if (value) {
    selectedRow.value = id;
  } else {
    if (selectedRow.value === id) selectedRow.value = null
  }
}
function selectRowItem(item: number) {
  emit('row-select', item);
}
function selectAll(value: boolean) {
  emit('row-select', value ? tableData.value.map(td => td.id) : []);
}

function updatePaginationData(pagination: Pagination) {
  pageStart.value = pagination.pageStart + 1
  pageStop.value = pagination.pageStop
  itemsLength.value = pagination.itemsLength
  page.value = pagination.page > pagination.pageCount ? pagination.pageCount : pagination.page;
  emit('pagination', pagination);
}
function expandChildren(item: Item) {
  if (item.children && item.children.length) {
    if (expanded.value.includes(item.id)) {
      // remove this item from the expanded array
      expanded.value = expanded.value.filter(e => e !== item.id)
    } else {
      // add this item to the expanded array
      expanded.value = expanded.value.concat(item.id);
    }
  }
}
function emitSelect(value: string | number | boolean | object, item: Item) {
  emit(value as string, item);
}
function selectEvent(item: PageMenuItem) {
  itemsPerPage.value = parseInt(item.value);
  itemsPerPageOption.value = item;
  page.value = 1;
}

</script>
<style lang="scss">
@import "../assets/styles/main";

@mixin cells-padding {
  padding: 0px !important;

  > * {
    left: 12px;
  }

  &:first-child {
    >* {
      left: 32px;
    }
  }
}

@mixin row-hover {
  td {
    background-color: $accentClear;
  }

  td:first-child {
    .v-icon.v-icon {
      color: $accent !important;
    }
  }
}

.table-row-hover {
  &:not(.expanded-row--color):not(.table-row--selected) {
    @include row-hover;
  }
}

.v-data-table--mobile {
  & > .v-data-table__wrapper {
    tbody {
      display: table-row-group !important;
    }
  }
}

.eewc-table {
  border: 1px solid $elements;

  & > .v-data-table__wrapper {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }

  td:not(:last-child),
  th {
    position: relative;

    & > * {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  thead {
    .header-selector {
      display: none;
      position: absolute;
      margin-top: 11px;
      margin-left: 8px;

      .v-input--selection-controls__input {
        height: 20px !important;
        width: 20px !important;
      }

      .v-icon, svg {
        height: 12px !important;
        width: 12px !important;
      }

      &:hover, &--block {
        display: block;
      }
    }
    tr {
      background-color: $backgrounds;

      th {
        border-bottom: 1px solid $elements  !important;
        height: 44px !important;
        @include cells-padding;

        .text-content {
          color: $secondary;
        }

        .table-header__hover-sort-icon {
          visibility: collapse;
        }

        .table-header--center-text-sortable {
          padding-left: 13px;
        }

        &:hover {

          .table-header__hover-sort-icon {
            visibility: visible;
          }
        }

        .header-skeleton {
          &.center {
            margin: auto;
          }
        }
      }

      .eewc-table__header--sortable{
        &:hover {
          .text-content {
            color: $primary;
          }
        }
      }
    }
    &:hover {
      .header-selector {
        display: block;
      }
    }
  }

  tbody {
    .row-selector {
      display: none;
      position: absolute;
      margin-top: -30px;
      margin-left: 8px;

      .v-input--selection-controls__input {
        height: 20px !important;
        width: 20px !important;
      }

      .v-icon, svg {
        height: 12px !important;
        width: 12px !important;
      }

      &:hover {
        display: block;
      }
    }
    tr {
      td {
        border-bottom: none !important;
        height: 40px !important;
        @include cells-padding;
        @include body-2;
        color: $primary;

        &.table__table-cell--last-cell {
          width: 1%;

          & > div {
            width: 48px;
          }
        }

        &:not(.table__table-cell--last-cell) {

          .v-icon__component,
          .v-icon__svg {
            width: 24px;
            height: 24px;
          }
        }

        &:first-child {
          .v-icon {
            position: unset !important;
            color: $primary;
          }
        }
      }

      // used nth-of-type to not select the checkbox, as it's in the same level as the row.
      // they're in the same level because we don't want the checkbox to mess with the row contents/cells.
      &:nth-of-type(even) {
        background-color: $backgrounds;
      }

      &:not(.expanded-row--color):not(.table-row--selected):hover {
        @include row-hover;
      }

      &:hover + .row-selector, &.selected + .row-selector {
        display: block;
      }

      &.expanded-row--color:not(.table-row--selected) {
        background: $accentLight  !important;

        td:first-child {
          .v-icon.v-icon {
            color: $accent  !important;
          }
        }
      }
    }
  }

  &.eewc-table--dense {
    .header-selector {
      margin-top: 6px;
    }

    tbody { 
      .row-selector {
        padding-top: 4px;
      }
    }

    th {
      height: 36px !important;
    }

    td {
      height: 32px !important;
    }
  }

  .table-row--selected {
    td {
      background-color: $elements  !important;
    }
  }

  .table-footer{
    background-color: $backgrounds;
    border-radius: 0px 0px 4px 4px;
    border-top: 1px solid $elements;

    &__pagination {
      @include subtitle-2;
      color: $primary;
    }

    &__page-size {
      max-width: 300px;
      margin-left: 16px !important;
      margin-right: 16px !important;
      color: $primary;
    }

    &__page-arrow:before {
      background-color: unset;
    }
  }
}
</style>
