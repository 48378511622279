<template>
  <div
    class="eewc-form-field"
    :class="big ? 'big' : ''"
    :style="{ gridTemplateColumns: labelWidthValue? (labelWidthValue+' 1fr') :''}"
  >
    <div class="form-field__label d-flex flex-column justify-center align-end">
      <div class="label subtitle-2">
        {{ label }}
      </div>
      <div
        v-if="optional"
        class="optional"
      >
        {{ optional }}
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  label?: string,
  labelWidth?: number | string,
  optional?: string,
  big?: boolean,
}>()

const labelWidthValue = computed(() => props.labelWidth ? ((typeof props.labelWidth === 'number') ? props.labelWidth + 'px' : props.labelWidth) : undefined);

</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.eewc-form-field {
  display: grid;
  grid-template-columns: 126px 1fr;
  padding-left: 4px;

  &.big {
    grid-template-columns: 130px 1fr;
  }

  margin-top: 0;

  &:not(:last-child) {
    margin-bottom: 16px !important;
  }

  .form-field__label {
    text-align: right;
    padding-right: 12px;
    height: 36px;

    .label {
      color: $secondary;
    }

    .optional {
      font-size: 10px;
      line-height: 1;
      color: $secondaryMedium;
    }
  }
}
</style>
