<template>
  <div class="select-sort">
    <SelectBox
      v-model="selectedItem"
      :items="items"
      :disabled="disabled"
      :selection-slot="true"
      :item-slot="true"
      :solo="true"
      :flat="true"
      :outlined="false"
    >
      <template
        #selection="{ item }"
      >
        <div
          v-if="appendText"
          class="select-sort__selections--append-text"
        >
          {{ appendText + ":" }}
        </div>
        <div 
          :class="['select-sort__selections--selected-text', {'select-sort__selections--selected-text-disabled' : disabled}]" 
        >
          {{ item && item.text ? item.text : '' }}
        </div>
      </template>
      <template #item="{ item }">
        <div
          class="d-flex justify-space-between align-center"
          style="width:100%"
          :data-testid="item && item.text? item.text: ''"
        >
          {{ item && item.text ? item.text : '' }}
          <v-icon size="12">
            {{ item && item.image ? item.image : '' }}
          </v-icon>
        </div>
      </template>
    </SelectBox>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, WritableComputedRef } from 'vue'
import SelectBox from './SelectBox.vue';

type Item = {
  text: string,
  value:  string,
}

type Props = {
  value: Item,
  disabled?: boolean,
  items: Item[],
  appendText: string,
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'input', value: Item): void;
}>();
const valueProp = ref<Item>(props.value);

const selectedItem: WritableComputedRef<Item> = computed({
  get(): Item {
    return valueProp.value;
  },
  set(newValue: Item): void {
    if(valueProp.value !== newValue) {
      valueProp.value = newValue;
      emit("input", newValue);
    }
  },
});

</script>

<style lang="scss">
@import "../../assets/styles/main";

.select-sort {
  display: inline-grid;

  .v-input {
    &__slot {
      min-height: 36px !important;
      padding: 0 0 0 5px !important;

      &:hover {
        background: transparent !important;
        .select-sort__selections--selected-text {
          background: $accentClear !important;
          border-radius: 4px 0 0 4px;
        }
        .v-input__append-inner {
          background: $accentClear !important;
          border-radius: 0 4px 4px 0;
        }
      }
    }

    &__icon--append {
      .v-icon {
        color: $secondaryMedium !important;
        caret-color: $secondaryMedium !important;

        svg {
          color: unset !important;
          caret-color: unset !important;
        }

        &.v-icon.v-icon--disabled {
          color: $secondaryLight !important;
        }
      }
    }
  }

  &__selections {
    flex-wrap: unset;
    &--append-text {
      @include subtitle-2;
      margin-right: 4px;
      color: $primary !important;
    }

    &--selected-text {
      @include body-2;
      padding: 2px 4px;
      color: $primary;

      &-disabled {
        color: $secondaryLight;
      }
    }
  }
  .select-box .v-input__slot:hover {
    box-shadow: none !important
  }

  .v-text-field--solo > .v-input__control > .v-input__slot {
    background: unset;
  }

  .v-text-field.v-text-field--solo .v-input__control input {
    display: none;
  }
  .v-input__append-inner {
    margin-left: 0 !important;
  }
}

.v-menu__content .v-list-item {
  @include body-2;
}
</style>
