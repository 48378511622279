<template>
  <div
    v-click-outside="hideMultiSelectItems"
    class="multiselect-box"
  >
    <button
      class="multiselect-box__select"
      :disabled="disabled"
      :class="{ 'multiselect-box__select--disabled': disabled, 'multiselect-box__select--active': selectActive }"
      @click="selectActive = !selectActive;"
    >
      {{ selectedItemsLabel }}
      <v-icon
        :size="24"
        class="ml-auto"
      >
        $icon_arrow_down
      </v-icon>
    </button>
    <div
      v-show="selectActive"
      class="multiselect-box__content multiselect-box__items"
    >
      <div
        class="multiselect-box__item"
      >
        <CheckBox
          v-model="isSelectedAll"
          :label="labelStrings.allLabel"
          :indeterminate="isIndeterminate"
          class="multiselect-box__item__checkbox"
        />
      </div>
      <div
        v-for="item in items"
        :key="item.value"
        class="multiselect-box__item"
        @click="clickItem(item)"
      >
        <CheckBox
          :label="item.text"
          :value="selectedItems?.some(selectedItem => selectedItem.value === item.value)"
          class="multiselect-box__item__checkbox"
          @change="clickItem(item)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import CheckBox from "../../selection-controls/CheckBox.vue";
import _ from "lodash";
import { Item, LabelStrings } from '../types';
import { clickItemHandler, selectedItemsLabelHandler } from "../multiSelectUtils";

const props = defineProps<{
  disabled?: boolean,
  value?: Item[],
  labelStrings: LabelStrings,
  items: Item[],
}>();

const emit = defineEmits<{
  (e: "input", value?: Item[]): void;
}>();

const selectActive = ref<boolean>(false);
const isIndeterminate = ref(props?.value && _.inRange(props?.value?.length, 1, props.items.length));

const isSelectedAll = computed({
  get: () => props?.value?.length === props.items.length,
  set: (newValue) => {
    selectedItems.value = newValue ? props.items : [];
  }
})

const selectedItems = computed({
  get: () => props.value ? props.value : [],
  set: (newValue) => {
    isIndeterminate.value = _.inRange(newValue.length, 1, props.items.length);
    emit('input', newValue);
  }
})

const selectedItemsLabel = computed(() =>
  selectedItemsLabelHandler(isSelectedAll.value, selectedItems.value, props.labelStrings)
);

const hideMultiSelectItems = () => selectActive.value = false;

const clickItem = (item: Item) => selectedItems.value = clickItemHandler(item, selectedItems.value);


</script>

<style lang="scss" scoped>
@import "../../../assets/styles/main";
@import "../multiSelectStyles";
.multiselect-box {
  &__item {
    padding: 2px 0;
    &:hover {
      background: $accentClear;
    }
  }
}
</style>