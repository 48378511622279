<template>
  <div>
    <v-autocomplete
      v-model="selectedItem"
      :label="labelText"
      :items="items"
      :append-icon="appendIcon"
      filled
      dense
      height="36px"
      single-line
      outlined
      class="dropdown__simple"
      :class="{ 'dropdown__simple--disabled' : disabled,
                'dropdown__simple--no-translate ': stopIconTransform }"
      :disabled="disabled" 
      return-object
      item-color="white"
      :loading="loading"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type ValueType = { text: string, value: string | number } | string;

const props = withDefaults(defineProps<{
  disabled?: boolean,
  value: ValueType,
  items: Array<ValueType>,
  labelText?: string,
  loading?: boolean,
  appendIcon?: string,
  stopIconTransform?: boolean,
}>(),
  {
    appendIcon: "$icon_arrow_down",
    stopIconTransform: false,
    disabled: false,
    labelText: '',
  });

const emit = defineEmits<{
  (e: "input", value?: ValueType): void;
}>();

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  }
})

</script>


<style lang="scss">

@import "../../assets/styles/main";
.dropdown__simple .v-input__append-inner{
  margin: 6px 0 !important;
  align-self: center;
}

.dropdown__simple .v-label {
  position: unset !important;
}

.dropdown__simple {
  position: relative;
  .v-text-field__details {
    position: absolute;
    bottom: -24px;
    left: -12px;
    .v-messages__message {
      @include body-2;
    }
  }
  input {
    font-size: 14px;
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative !important;
    }
  }
  .v-input__slot {
    min-height: 36px !important;
    padding-right: 8px !important;
    & fieldset {
      background: $backgrounds;
      color: $elements !important;
      border: 1px solid;
    }
    input {
      font-size: 14px;
      color: $primary;
    }

    & label {
      @include body-2;
      color: $secondaryMedium;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    }

    &:active{
      & fieldset {
        background: $accentClear;
        border: 1px solid $accent;
      }
    }
    z-index: 2;
    .v-list-item{
      min-height: 28px !important;
    }

    .v-list-item--active{
      background: $accent !important;
      border-radius: 4px;
    }
  }
}

.v-autocomplete__content.v-menu__content {
  box-shadow: 2px 4px 12px 0px #2A344014, 0 0 0 1px $elements;
  border-radius: 4px;
  padding: {
    right: 4px;
    left: 4px;
  }
}

.dropdown__simple {
  &--disabled .v-input__slot {
    & fieldset {
      background: $elements;
    }
  }

  &--no-translate {
    .notranslate {
      transform: none!important;
    }
  }
}

</style>
