<template>
  <v-dialog
    v-model="showDialogViewModel"
    width="360px"
  >
    <v-card
      width="360px"
      min-height="172px"
      class="d-flex flex-column"
    >
      <v-card-title class="subtitle-2 card__title-text">
        <v-icon
          :style="iconBackground"
          :color="iconColor"
          class="card__icon--background mr-3"
        >
          {{ icon }}
        </v-icon>
        <slot name="title" />
      </v-card-title>
      <v-card-text class="body-2 card__text-padding flex-grow-1">
        <slot name="body" />
      </v-card-text>
      <v-card-actions class="float-right card__actions-padding align-self-end">
        <slot name="buttonleft" />
        <div class="pl-3">
          <slot name="buttonright" />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    showDialog?: boolean;
    icon?: string;
    iconColor?: string;
  }>(), {
    icon: '$icon_delete',
    iconColor: 'negative',
  }
);

const emit = defineEmits<{
  (e: "closeDialog"): void;
}>();

const iconBackground =  computed(() => { return { 'background-color': `var(--v-${props.iconColor}Clear-base)` }});
const showDialogViewModel = computed({
  get: () => props.showDialog,
  set: (value) => {
    if (!value) emit("closeDialog");
  }
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.card__text-padding {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.card__title-text {
  padding: 22px 20px 14px !important;
  color: $primary;
  align-items: flex-start;
}

.card__actions-padding {
  padding: 0 20px 20px !important;
}
.card__icon--background {
  border-radius: 4px;
}
</style>
