<template>
  <div :class="`nav-footer ${mini ? 'nav-footer--mini' : ''}`">
    <v-menu
      v-model="helpToggled"
      nudge-top="50"
      :nudge-right="helpMenuNudgeRight"
      tile
      content-class="list-group__menu-content"
    >
      <template #activator="{ on, attrs }">
        <div
          v-if="helpCenter"
          v-bind="attrs"
          v-on="on"
        >
          <div
            v-if="vuetify.breakpoint.mdAndDown || mini"
            class="nav-footer__help-center"
          >
            <tooltip :text="helpCenter">
              <template #content="{on: tooltipOn, attrs: tooltipAttrs}">
                <v-btn
                  text
                  v-bind="tooltipAttrs"
                  v-on="tooltipOn"
                >
                  <v-icon
                    class="nav-icon"
                    size="24"
                  >
                    $icon_help
                  </v-icon>
                </v-btn>
              </template>
            </tooltip>
          </div>

          <div
            v-else
            class="nav-footer__help-center"
          >
            <div
              text
              class="help-center__toggler"
            >
              <v-icon class="nav-icon">
                $icon_help
              </v-icon>
              {{ helpCenter }}
            </div>
          </div>
        </div>
      </template>
      <v-list
        dense
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in helpItems"
          :key="i"
          class="nav-footer__list-item"
          @click="item.click"
        >
          <v-icon
            color="secondaryLight"
            size="20"
            class="mr-3 list-item__sideIcon-color"
          >
            {{ item.icon }}
          </v-icon>
          <v-list-item-title 
            class="list-item__text-color"
          >
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider class="nav-footer__divider" />

    <div
      v-if="vuetify.breakpoint.mdAndDown || mini"
      class="nav-footer__user-menu"
      @dblclick.shift.exact="emit('onusershiftdblclick')"
    >
      <v-menu
        :nudge-right="helpMenuNudgeRight"
        content-class="list-group__menu-content nav-footer__user-menu-lastItem"
      >
        <template #activator="{ on }">
          <v-btn
            text
            v-on="on"
          >
            <div class="user-menu__toggler">
              <tooltip :text="profileTooltip">
                <template #content="{on: tooltipOn, attrs: tooltipAttrs}">
                  <v-icon 
                    :color="isAnyUserItemActive? 'accent' : 'white'" 
                    class="nav-icon"
                    v-bind="tooltipAttrs"
                    v-on="tooltipOn"
                  >
                    $icon_user
                  </v-icon>
                </template>
              </tooltip>
            </div>
          </v-btn>
        </template>
        <v-list
          dense
          class="zero"
          color="primary"
        >
          <v-list-item class="nav-footer__list-item">
            <v-list
              class="mt-0"
              dense
              color="primary"
            >
              <span class="nav-footer__profile-name">
                {{ userName }}
              </span>
              <span class="nav-footer__profile-mail">
                {{ email }}
              </span>
            </v-list>
          </v-list-item>
          <v-list-item
            v-for="(item, i) in userItems"
            :key="i"
            class="nav-footer__list-item"
            @click="item.click"
          >
            <v-icon
              :color="item.active? 'accent' : 'secondaryLight'"
              size="20"
              class="mr-3 list-item__sideIcon-color"
            >
              {{ item.icon }}
            </v-icon>
            <v-list-item-title 
              class="list-item__text-color"
              :class="['list-item__text-color',{'list-item__text-color--highlight': item.active}]"
            >
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div> 
    <div
      v-else
      class="nav-footer__user-menu"
      @dblclick.shift.exact="emit('onusershiftdblclick')"
    >
      <v-menu
        :nudge-right="helpMenuNudgeRight"
        content-class="list-group__menu-content nav-footer__user-menu-lastItem"
      >
        <template #activator="{ on }">
          <v-btn
            text
            :class="['user-menu__name',{'color--highlight': isAnyUserItemActive}]"
            v-on="on"
          >
            <div class="user-menu__toggler">
              <v-icon
                color="white"
                class="nav-icon"
              >
                $icon_user
              </v-icon>
            </div>
            <span class="user-menu__name-text"><eewc-tooltipped-text
              :max-width="112"
              :text="userName"
            /></span>
            <v-icon class="nav-icon">
              $icon_settings
            </v-icon>
          </v-btn>
        </template>
        <v-list
          dense
          color="primary"
        >
          <v-list-item class="nav-footer__list-item">
            <v-list
              class="mt-0"
              dense
              color="primary"
            >
              <span class="nav-footer__profile-name">
                {{ userName }}
              </span>
              <span class="nav-footer__profile-mail">
                {{ email }}
              </span>
            </v-list>
          </v-list-item>

          <v-list-item
            v-for="(item, i) in userItems"
            :key="i"
            class="nav-footer__list-item"
            @click="item.click"
          >
            <v-icon
              :color="item.active ? 'accent' : 'secondaryLight'"
              size="20"
              class="mr-3 list-item__sideIcon-color"
            >
              {{ item.icon }}
            </v-icon>
            <v-list-item-title 
              :class="['list-item__text-color', {'list-item__text-color--highlight': item.active }]"
            >
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import Tooltip from '../../tooltip/Tooltip.vue';
import { HelpItems, UserItems } from './MenuBar.vue';
import vuetify from '../../../service/useVuetify';

const props = defineProps<{
  mini: boolean,
  helpCenter?: string,
  profileTooltip: string,
  helpItems: HelpItems,
  userItems: UserItems,
  userName: string,
  email: string,
}>();

const emit = defineEmits<{
  (e: "onusershiftdblclick"): void;
}>();

const helpToggled = ref(false);

const helpMenuNudgeRight = computed(() => {
  return (vuetify.value.breakpoint.mdAndDown || props.mini) ? 35 : (vuetify.value.breakpoint.name === 'xl' ? 230 : 196)
})

const isAnyUserItemActive = computed(() => props.userItems.some((item) => item.active));

</script>

<style lang="scss">
@import "../../../assets/styles/main";


.nav-footer {
  @include flex-column;
  position: fixed !important;
  bottom: 12px !important;
  width: 100%;

  .color--highlight {
    background: $primaryMedium;
    * {
      color: $accent;
    }
  }
  &__profile-name{
    color: $secondaryLight !important;
    @include subtitle-2;
  }
  &__profile-mail{
    @include tooltips;
    color: $secondaryMedium !important;
  }
  &__list-item {
    .list-item__text-color {
      color: $secondaryLight !important;
      @include subtitle-2;

      &--highlight {
        color: $accent !important;
      }
    }
    &:hover {
      background: unset !important;
      .list-item__text-color {
        color: $accent !important;
      }
      .list-item__sideIcon-color {
        svg {
          color: $accent !important;
        }
      }
    }
  }
  &__divider {
    margin: 12px 0 !important;
  }

  @media (max-width: $MD) {
    padding: 0 9px !important;
  }
  @media (min-width: $MD) {
    padding: 0 10.5px !important;
  }
  @media (min-width: $LG) {
    padding: 0 12px !important;
    padding-bottom: 15px !important;
  }

  &__help-center {
    @include flex-center;
    width: 100%;
    border-radius: 4px;
    padding: 0;
    cursor: pointer;
    height: 40px;
    > div {
      padding: 0 12px !important;
    }


    button{
      @media (max-width: $LG) {
        padding: 0 !important;
        min-width: unset !important;
      }
    }

    .nav-icon {
      margin-right: 12px !important;
    }

    @media (max-width: $LG) {
      &:hover {
        .nav-icon {
          color: $accent;
        }
      }
    }

    .help-center__toggler  {
      @include subtitle-1;
      color: white !important;
      width: 100%;
      justify-content: flex-start !important;
    }

    @media (min-width: $LG) {
      &:hover {
        * {
          color: $accent !important;
        }
      }
    }
  }

  &__user-menu {
    @include flex-center;
    padding: 0px !important;
    width: 100%;
    &, button {
      height: 40px !important;
    }
    button{
      padding: 0 8px !important;
      @media (max-width: $LG) {
        padding: 0 !important;
        min-width: unset !important;
      }
    }

    &-lastItem{
       margin-top: 12px !important;
    }

    .user-menu {
      &__name-text{
        flex-grow: 1;
        text-align: left;
      }

      &__name  {
        @include subtitle-1;
        color: white !important;
        width: 100%;
        justify-content: flex-start !important;
      }
      &__toggler {
        @include flex-center;
        background-color: $primaryMedium;
        border-radius: 50%;

        @media (max-width: $LG) {
          @include width-height(32px);
        }
        @media (min-width: $LG) {
          @include width-height(28px);
          margin-right: 8px;
        }
        .nav-icon {
          @media (min-width: $SM) {
            @include width-height(20px);
          }
          @media (min-width: $LG) {
             margin: 4px;
          }
        }
      }
    }
  }

  &--mini {
    padding: 0 9px !important;
    .nav-footer {
      &__user-menu, &__help-center {
        button{
          padding: 0 !important;
          min-width: unset !important;
        }
      }
      &__help-center {
        .nav-icon {
          margin-right: 0 !important;
        }
        &:hover {
          .nav-icon {
            color: $accent !important;
          }
        }
      }
    }
    .user-menu__toggler {
      @include width-height(32px);
      margin-right: 0;
    }
  }
}

</style>