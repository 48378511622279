<template>
  <v-skeleton-loader
    class="skeleton-loader"
    :class="skeletonClasses"
    type="image"
    :style="{ maxWidth }"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
const props = withDefaults(
  defineProps<{
    type?: string;
    maxWidth?: string,
    fullHeight?: boolean,
  }>(),
  {
    type: 'text'
  }
)

const skeletonClasses = computed(() => {
  return {
    'skeleton-loader--full-height': props.fullHeight,
    [`${props.type}`]: true
  }
})
</script>

<style lang="scss">
  @import "../../assets/styles/main";

  .skeleton-loader {
    &.text{
      height: 16px;
    }

    &.title{
      height: 24px;
      max-width: 220px;

      @media (min-width: $MD) {
        max-width: 466px;
      }
    }

    &.image, .v-skeleton-loader__image {
      height: 222px;
    }

    &--full-height{
      &.image, .v-skeleton-loader__image {
        height: 100%;
      }
    }

    &.big-icon{
      height: 24px;
      width: 24px;
    }

    &.icon{
      height: 16px;
      width: 16px;
    }
  }
</style>