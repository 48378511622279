<template>
  <v-textarea
    v-model="selectedItem"
    :disabled="disabled"
    :rules="[...rules]"
    :class="[{ 'eewc-text-area__disabled': disabled }, { 'eewc-text-area--error': error }]"
    class="text-area"
    solo
    dense
    :label="label"
    :error="error"
    outlined
    flat
    single-line
    @focus="$emit('focus')"
  />
</template>

<script setup lang="ts">

import { computed } from 'vue'

type value = string | number | boolean | object;

type Props = {
  disabled?: boolean,
  error?: boolean,
  value?: value,
  label?: string,
  rules?: [],
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  error: false,
  value: '',
  rules: () => [],
});


const emit = defineEmits<{
  (e: 'input', value: value): void;
}>();


const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    emit("input", newValue);
  }
})
</script>

<style lang="scss">
@import "../../assets/styles/main";

.text-area .v-input__append-inner {
  margin-top: unset !important;
  align-self: center;
}

.text-area {
  label {
    animation: none !important;
  }

  position: relative;

  .v-text-field__details {
    position: absolute;
    bottom: -24px;
    left: -12px;

    .v-messages__message {
      @include body-2;
    }
  }

  .v-input__prepend-inner {
    z-index: 1;
    margin-top: 6px !important;
    color: $elements !important;
  }

  .v-icon {

    .v-icon__component,
    .v-icon__svg {
      height: 24px;
      width: 24px;
      color: $secondaryMedium !important;
    }
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative !important;
    }
  }

  .v-input__slot {
    min-height: 36px !important;

    & fieldset {
      color: $elements !important;
      border: 1px solid;
      background: #f7f8fc;
    }

    .v-text-field__slot {
      textarea {
        @include body-2
      }
    }


    & label {
      top: 8px !important;
      color: $secondaryMedium;
      @include body-2;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      border-radius: 4px;

      & fieldset {
        color: $elements !important;
        border: 1px solid;
      }
    }

    &:focus-within {
      & fieldset {
        color: $accent !important;
      }
    }

    textarea {
      color: $primary;
    }

    z-index: 2;
  }
}

.text-area--disabled .v-input__slot {
  & fieldset {
    background: $elements;
  }
}

.eewc-text-area--error .v-input__slot {
  & fieldset {
    color: $negative;
  }
}
</style>
