<template>
  <div @click="focus()">
    <v-combobox
      ref="combobox"
      v-model="chips"
      multiple
      solo
      hide-details
      :disabled="disabled"
      :class="`chips__combobox ${!showTag ? 'no-placeholder' : ''} ${!(chips && chips.length) ? 'no-chips' : ''} ${smallInput ? 'small-input' : ''}`"
      @blur="blur"
    >
      <template #selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          close-icon="$icon_close"
          class="chips__input"
          color="$primary"
          :class="{ 'chips__input--disabled': disabled }"
          @click="select"
          @click:close="remove(item)"
        >
          <tooltipped-text :text="item" />
        </v-chip>
      </template>
      <template
        v-if="showTag && !disabled"
        #append
      >
        <div class="chips__input--label">
          {{ placeholder }}
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref } from "vue";
import TooltippedText from "../tooltip/TooltippedText/TooltippedText.vue";

type ValueType = string

const props = withDefaults(
  defineProps<{
    placeholder?: string,
    disabled?: boolean,
    value?: Array<ValueType>,
    smallInput?: boolean,
  }>(), {
    placeholder: ''
  }
);

const emit = defineEmits<{
  (e: "input", value?: Array<ValueType>): void;
  (e: "input-change", value: string): void;
  (e: "input-focus"): void;
}>();

const showTag = ref(true);
const combobox = ref();

const chips = computed({
  get: () => props.value,
  set: (newValue) => emit('input', newValue)
});

onMounted(
  async () => {
    await nextTick(() => {
      if(!combobox.value) {
        return;
      }
      const input = combobox.value.$refs.input;
      const appendedText = combobox.value.$refs['append-inner'];
      input.parentNode.appendChild(appendedText);
  
      input.addEventListener('input', change);
    });
  }
);

onUnmounted(
  () => {
    if(!combobox.value) {
        return;
      }
    const input = combobox.value.$refs.input;
    input.removeEventListener('input', change);
  }
)

function change(e: InputEvent) {
  emit('input-change', (e.target as HTMLInputElement).value);
}

function focus() {
  showTag.value = false;
  emit('input-focus');
}

function blur() {
  showTag.value = true;
}

function remove(item: string) {
  chips.value = chips.value && chips.value.filter(c => c !== item);
  showTag.value = false;
}

defineExpose({ focus });
</script>

<style lang="scss">
@import "../../assets/styles/main";

.chips__combobox {

  .v-select__selections {
    margin-bottom: -4px;
  }

  .v-input__control {
    min-height: unset !important;
  }

  .chips__input {
    border-radius: 40px !important;
    height: 24px !important;
    color: white !important;
    background-color: $primary  !important;

    &:not(:last-child) {
      margin: unset;
      margin-right: 4px;
      margin-bottom: 4px;
    }

    .v-chip__content {
      @include body-2;
    }
  }

  .v-input__slot {
    border: 1px solid $elements;
    color: $elements  !important;
    box-shadow: none !important;
    background: $backgrounds !important;
    align-items: baseline;
    display: grid;
    overflow: auto;
    overflow: overlay;
    padding: 7px 11px !important;

    &::-webkit-scrollbar {
      width: 12px;
    }

    & {
      scrollbar-width: thin;
      scrollbar-color: $secondaryMedium $backgrounds;
    }

    &::-webkit-scrollbar-track {
      background: $backgrounds;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondaryMedium;
      border-radius: 8px;
      border: 4px solid $backgrounds;
      position: absolute;
      right: 10px;
    }

    .v-input__icon--append {
      display: none;
    }

    .v-input__append-inner {
      color: $secondaryMedium;
      font-size: 14px;
      margin-left: unset;
      line-height: 24px;
      margin-top: -2px;
    }

    &:hover {
      .v-input__append-inner {
        color: $accent;
      }
    }

    .v-select__slot {
      flex-direction: column;
      align-items: unset;

      input {
        margin-top: -2px !important;
        margin-right: -4px !important;
        flex: unset;
        width: 3px !important;
        min-width: 3px !important;
        font-size: 14px !important;
        height: 24px;
        max-height: unset !important;
      }
    }
  }

  &.no-chips {
    .v-select__slot {
      input {
        margin-right: -8px !important;
      }
    }
  }

  &.no-placeholder {
    .v-input__slot {
      border: 1px solid $accent;
    }

    .v-select__slot {
      input {
        width: 55px !important;
        min-width: 55px !important;
        flex: 1 1;
        color: $primary;
      }
    }
  }

  &.small-input {
    .v-input__slot {
      padding: 5px 7px !important;
    }
  }

  &.v-input--is-disabled {
    .v-input__slot {
      background: $elements  !important;
    }
  }

  .chips__input--disabled {
    background-color: $secondary  !important;
  }
}
</style>
