<template>
  <button
    :class="[
      'een-button',
      {
        isIcon: icon,
        hasAppendIcon: appendIcon,
        small,
        'een-button__small-padding': smallPadding
      },
      (!loading && color) && (color + 'Btn'),
      (!loading && type) && (type + 'Btn'),
      !loading
        ? {
          disabled: disabled,
          onImage: onImage,
          map: map,
        }
        : 'loading-button',
      { block: block },
      { overlay: overlay },
      { 'overlay-disabled' : overlay && disabled}]"
    @click="e => buttonClick(e)"
  >
    <slot>
      {{ text }}
    </slot>
    <v-icon
      v-if="appendIcon && !loading"
      right
      :size="iconSize"
      :color="iconColor"
    >
      {{ appendIcon }}
    </v-icon>
  </button>
</template>


<script setup lang="ts">

const emit = defineEmits<{
  (e: 'click', event: Event): void
}>()

withDefaults(
  defineProps<{
    color?: string
    type?: string
    icon?: boolean
    onImage?: boolean
    map?: boolean
    text?: string
    disabled?: boolean
    loading?: boolean
    appendIcon?: string
    iconSize?: number
    iconColor?: string
    block?: boolean
    small?: boolean
    smallPadding?: boolean
    overlay?: boolean
  }>(),
  {
    color: "",
    type: "",
    icon: false,
    onImage: false,
    map: false,
    text: "",
    disabled: false,
    loading: false,
    appendIcon: "",
    iconSize: 24,
    iconColor: "",
    block: false,
    small: false,
    smallPadding: false,
    overlay: false,
  }
)

function buttonClick(event: Event) {
  emit('click', event)
}

</script>

<style lang="scss">
@import "../../assets/styles/main";

.een-button {
  height: 36px;
  padding: 0 16px;
  display: inline-block;
  text-align: center !important;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
  @include subtitle-2;

  &,
  &.primaryBtn {
    &,
    .v-icon {
      color: #ffffff;
    }
    background: $primary;
    &:hover {
      box-shadow: 2px 2px 2px rgba(42, 52, 64, 0.32);
      color: $elements;
    }
    &:active {
      // pressed
      box-shadow: unset;
      background: $primaryLight !important;
    }
  }

  &.block{
    width: 100%;
  }

  &.overlay{
    opacity: 0.72;
    color: #fff !important;
    &:hover {
      color: #E4E6EA !important;
    }
    &:active {
      // pressed
      color: #444D56 !important;
    }
  }

  &.accentBtn {
    background: $accent;
    &:active {
      // pressed
      background: $accentDark !important;
    }
  }

  &.positiveBtn {
    background: $positive;
    &:active {
      // pressed
      background: $positiveDark !important;
    }
  }

  &.warningBtn {
    background: $warning;
    &:active {
      // pressed
      background: $warningDark !important;
    }
  }

  &.negativeBtn {
    background: $negative;
    &:active {
      // pressed
      background: $negativeDark !important;
    }
  }

  &.depressedBtn {
    &,
    .v-icon {
      color: $primary;
    }
    &, &.primaryBtn {
      // to cancel vuetify's primary color
      background-color: $elements !important;
      border-color: unset !important;

      background: $elements;
      &:active {
        // pressed
        box-shadow: unset;
        background: $secondaryLight;
      }
    }

    &.accentBtn {
      &,
      .v-icon {
        color: $accent;
      }
      background: $accentClear !important;
      &:active {
        // pressed
        background: $accentLight !important;
      }
    }

    &.positiveBtn {
      &,
      .v-icon {
        color: $positive;
      }
      background: $positiveClear !important;
      &:active {
        // pressed
        background: $positiveLight !important;
      }
    }

    &.warningBtn {
      &,
      .v-icon {
        color: $warning;
      }
      background: $warningClear !important;
      &:active {
        // pressed
        background: $warningLight !important;
      }
    }

    &.negativeBtn {
      &,
      .v-icon {
        color: $negative;
      }
      background: $negativeClear !important;
      &:active {
        // pressed
        background: $negativeLight !important;
      }
    }
  }

  &.outlinedBtn,
  &.clearBtn {
    &,
    .v-icon {
      color: $primary;
    }
    &, &.primaryBtn {
      // to cancel vuetify's primary color
      background-color: unset !important;
      border-color: $secondaryLight !important;

      border: 1px solid $secondaryLight;
      background: unset !important;
      box-shadow: unset !important;
      &:hover {
        background: $elements !important;
      }
      &:active {
        // pressed
        background: $secondaryLight !important;
      }
    }

    &.accentBtn {
      &,
      .v-icon {
        color: $accent;
      }
      border: 1px solid $accentLight !important;
      background: unset !important;
      &:hover {
        background: $accentClear !important;
      }
      &:active {
        // pressed
        background: $accentLight !important;
      }
    }

    &.positiveBtn {
      &,
      .v-icon {
        color: $positive;
      }
      border: 1px solid $positiveLight !important;
      background: unset !important;
      &:hover {
        background: $positiveClear !important;
      }
      &:active {
        // pressed
        background: $positiveLight !important;
      }
    }

    &.warningBtn {
      &,
      .v-icon {
        color: $warning;
      }
      border: 1px solid $warningLight !important;
      background: unset !important;
      &:hover {
        background: $warningClear !important;
      }
      &:active {
        // pressed
        background: $warningLight !important;
      }
    }

    &.negativeBtn {
      &,
      .v-icon {
        color: $negative;
      }
      border: 1px solid $negativeLight !important;
      background: unset !important;
      &:hover {
        background: $negativeClear !important;
      }
      &:active {
        // pressed
        background: $negativeLight !important;
      }
    }

    &.disabled {
      border: 1px solid $secondaryLight;
      &,
      .v-icon {
        color: $secondaryMedium;
      }
      background: unset !important;
    }
  }

  &.clearBtn {
    &,
    &.accentBtn,
    &.positiveBtn,
    &.warningBtn,
    &.negativeBtn,
    &.disabled {
      border: unset !important;
    }
  }

  &.onImage {
    backdrop-filter: blur(4px);
    background: rgba(33, 42, 52, 0.72);
    &:active {
      // pressed
      background: rgba(68, 77, 86, 0.72) !important;
    }
  }

  &.hasAppendIcon {
    padding-right: 8px !important;
    grid-template-columns: auto auto;
    gap: 4px;
  }

  &.isIcon {
    width: 36px;
    height: 36px;
    padding: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .v-icon {
      margin: 0 !important;
    }
    &:hover {
      background: $primaryMedium;
    }
    &.small {
      height: 24px;
      width: 24px;
    }
  }

  &.map {
    background: white !important;
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    &,
    .v-icon {
      color: $primary;
    }
    &:hover {
      &,
      .v-icon {
        color: $secondaryMedium !important;
      }
    }
    &.disabled {
      &,
      .v-icon {
        color: $secondaryLight !important;
      }
    }
  }

  &:focus-visible {
    position: relative;
    border: unset !important;
    outline: unset !important;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid $primaryWhite;
      box-shadow: 0px 0 0 1px $primary;
      border-radius: 4px;
    }
  }

  &.loading-button {
    position: relative;
    border: unset !important;
    &,
    .v-icon {
      color: transparent !important;
    }
    pointer-events: none;
    background: $elements !important;
    background-color: $elements !important;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 2px solid rgba(white, 0.32);
      border-top-color: $primary;
      animation: loading-button 1.1s linear infinite;
      @keyframes loading-button {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  
  &.disabled {
    pointer-events: none;
    color: $secondaryMedium;
    &:active {
        // pressed
        background: $elements !important;
        box-shadow: unset;
      }
    &,
    .v-icon {
      color: $secondaryMedium;
    }
    cursor: default;
    background: $elements !important;
    background-color: $elements !important;
  }

  &.overlay-disabled {
    color: #969CA4 !important ;
    background: $primary !important;
    background-color: $primary !important;
  }
  
  &__small-padding{
    padding: 0 8px;
  }
}

.een-button::first-letter {
  text-transform: uppercase;
}
</style>
