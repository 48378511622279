<template>
  <div>
    <v-menu 
      :id="attachId ? attachId : false"
      v-model="show"
      :disabled="disabled"
      content-class="drop-down-menu"
      :min-width="!small ? width : '50'"
      :max-width="!small ? width : '50'"
      offset-y
      :nudge-right="small ? '-20' : (nudgeRight? -185 - nudgeRight : -185 )"
    >
      <template
        #activator="{ on, attrs }"
      >
        <v-tooltip
          bottom
          color="primary"
          z-index="1000"
          open-delay="200"
          close-delay="200"
          :disabled="show || !tooltip"
        >
          <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <div
              v-if="button"
              v-bind="tooltipAttrs"
              v-on="tooltipOn"
            >
              <button-common
                v-if="menuItems && menuItems.length"
                :id="attachId ? attachId : ''"
                :class="show ? 'drop-down-menu__open' : ''"
                :on-image="onImage"
                icon
                :icon-size="20"
                append-icon="$icon_more_vert"
                v-bind="attrs"
                :disabled="disabled"
                v-on="on"
              />
            </div>
            <div
              v-else-if="customToggler"
              v-bind="tooltipAttrs"
              class="d-inline-block"
              v-on="tooltipOn"
            >
              <slot
                name="toggler"
                :on="on"
                :attrs="attrs"
              />
            </div>
            <div
              v-else
              v-bind="tooltipAttrs"
              v-on="tooltipOn"
            >
              <v-icon
                v-if="menuItems && menuItems.length"
                :id="attachId ? attachId : ''"
                :size="iconSize ? iconSize : 24"
                :disabled="disabled"
                class="drop-down-menu__activator"
                :class="[
                  {
                    'drop-down-menu__activator--dark': dark,
                    'drop-down-menu__activator--collapsed': show,
                  }
                ]"
                v-bind="attrs"
                v-on="on"
              >
                $icon_more_vert
              </v-icon>
            </div>
          </template>
          <span class="drop-down-menu__tooltip">
            {{ tooltip }}
          </span>
        </v-tooltip>
      </template>
      
      <div
        v-for="item in menuItems"
        :id="(item.value || '').toString()"
        :key="item.name"
        :class="[
          'drop-down-menu__item',
          !!item.color ? `drop-down-menu__item--color-${item.color}` : '',
          {
            'drop-down-menu__item--warning': item.warning,
            'drop-down-menu__item--disabled': item.disabled,
            'drop-down-menu__item--active': item.active,
            'drop-down-menu__item--with-separator': item.withSeparator
          }
        ]"
        :data-testid="item.name"
        @click.stop="itemClick(item)"
      >
        {{ item.name }}
      </div>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import ButtonCommon from "../buttons/ButtonCommon.vue";

type MenuItem = {
  name: string,
  value: string | number | boolean | object | undefined,
  disabled?: boolean,
  warning?: boolean,
  active?: boolean,
  color?: string,
  withSeparator?: boolean
}

withDefaults(defineProps<{
  menuItems: MenuItem[]
  dark?: boolean
  button?: boolean
  onImage?: boolean
  small?: boolean
  tooltip?: string
  iconSize?: number
  nudgeRight?: number
  attachId?: string
  customToggler?: boolean
  disabled?:boolean
  width?:number
}>(), 
{
  width:202
});

const emit = defineEmits<{
  (e: 'menuCollapsed', value: boolean): void;
  (e: 'select', value: string | number | boolean | object ): void;
}>()

const show= ref(false);
watch(
  () => show.value,
  () => {
    emit('menuCollapsed', show.value)
  }
);

function itemClick(item: MenuItem) {
  show.value = false;
  if(!item.disabled && item.value) {
    emit('select', item.value)
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

@mixin separator {
  margin-top: 8px;

  &:before {
    content: '';
    width: calc(100% - 8px);
    border-top: 1px solid $elements;
    position: absolute;
    left: 4px;
    bottom: 40px;
    z-index: 1;
    pointer-events: none;
  }
}

.drop-down-menu {
  box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 4px !important;
  background: white;
  border: 1px solid $elements;
  box-sizing: border-box;
  margin-top: 9px !important;

  &__tooltip {
    @include tooltips;
    opacity: 1 !important;
  }

  &__open {
    box-shadow: unset;
    background: $primaryLight  !important;
  }

  &__activator {
    color: $primary;

    &:focus:after {
      opacity: 0;
    }

    &--collapsed {
      &, * {
        color: $secondaryMedium !important;
      }
    }

    &--dark {
      color: white;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    width: 100%;
    height: 28px;
    border-radius: 4px;
    background: white;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    cursor: pointer;
    color: $primary;
    @include subtitle-2;

    &:hover {
      background: $accentClear;
    }

    &:active {
      background: $secondaryLight;
      color: $secondary;
    }

    &--with-separator {
      @include separator;
    }

    &--warning {
      color: $negative;
      @include separator;

      &:hover {
        background: $negativeClear
      }

      &:active {
        color: $negative;
        background: $negativeLight
      }
    }

    &--color-warning {
      color: $negative;

      &:hover {
        background: $negativeClear
      }

      &:active {
        color: $negative;
        background: $negativeLight
      }
    }

    &--disabled {
      color: $secondaryLight;
      cursor: default;
      background: white !important;
      pointer-events: none;
    }

    &--active {
      background: $accent  !important;
      color: white !important;
    }
  }
}

</style>
