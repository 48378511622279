<template>
  <div
    class="progress-step__container my-2 my-lg-4"
    :style="{ 'max-width': maxWidthContainer ? maxWidthContainer + 'px' : 'unset' }"
  >
    <div
      v-for="(step, index) in steps"
      :id="step.id"
      :key="step.id"
      class="progress-step"
      :class="{
        'progress-step--last': index === steps.length - 1,
        'progress-step--current': value.id === step.id,
        'progress-step--completed': step.isCompleted
      }"
      @click="onStepClick(step, index)"
    >
      <div class="text-center progress-step__icon-container">
        <div class="progress-step__line" />
        <v-icon class="progress-step__icon">
          {{ step.icon ? step.icon : "$icon_check_zero" }}
        </v-icon>
      </div>
      {{ step.title }}
    </div>
  </div>
</template>

<script setup lang="ts">

import {Component} from "vue";

type step = {
  id: string
  title: string;
  icon: string;
  isCompleted: boolean;
  component: Component
};

const props = defineProps<{
  steps: step[];
  value: step;
  maxWidthContainer?: number;
}>();

const emit = defineEmits<{
  (e: "input", value: step): void;
}>();

function onStepClick(step: step, index: number) {
  const isCompletedPreviousStep = props.steps[index - 1] && props.steps[index - 1].isCompleted;
  const isFirst = index === 0;
  const isActiveStep = props.value.id === step.id;

  if ((!isCompletedPreviousStep && !isFirst) || isActiveStep) {
    return;
  }

  emit('input', step)
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.progress-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
  color: $secondaryMedium;
  z-index: 1;
  @include body-2;
  text-align: center;

  &__container {
    display: flex;
    justify-content: center;
    align-items: self-start;
    margin: auto;
    width: 100%;
  }

  &__icon-container {
    position: relative;
  }

  &__icon {
    border-radius: 50%;
    border: 2px solid $elements;
    position: relative;
    margin-bottom: 5px;
    background: $elements;
    color: #fff;

    .v-icon__component, .v-icon__svg {
      height: 24px;
      width: 24px;
    }
  }
  &__line {
    height: 3px;
    width: 100%;
    position: absolute;
    right: -50%;
    border-bottom: 3px solid;
    z-index: -1;
    top: 50%;
    margin-top: -4px;
    border-color: $elements;
  }
  &--last {
    .progress-step__line {
      display: none;
    }
  }
  &--completed {
    color: $primary;
    cursor: pointer;
    .progress-step__line {
      border-color: $accent;
    }
    .progress-step__icon {
      border-color: $accent;
      color: $primaryWhite;
      background: $accent;
    }

    &:hover {
      .progress-step__icon {
        border-color: $accentLight;
        background: $accentLight;
      }
    }
    &:active {
      color: $secondary;
      .progress-step__icon {
        border-color: $secondary;
        background: $secondary;
      }
    }
    + .progress-step {
      cursor: pointer;
    }
  }
  &#{&}--current {
    color: $accent;
    cursor: default;
    font-weight: 500;
    .progress-step__line {
      border-color: $elements;
    }
    .progress-step__icon {
      border-color: $accent;
      background: $primaryWhite;
      color: inherit;
    }
  }
  &--current#{&}--completed {
    cursor: default;
    .progress-step__line {
      border-color: $accent;
    }
    .progress-step__icon {
      border-color: $accent;
      background: $primaryWhite;
      color: inherit;
    }
  }
}
</style>
