<template>
  <div class="text-center">
    <v-chip
      v-model="checked"
      class="chips__filter"
      :class="{ 'chips__filter--checked' : checked }"
      label
      :disabled="disabled"
      tabindex="1"
      @click="checked = !checked" 
    >
      <v-icon
        v-if="checked"
        left
        color="white"
      >
        $icon_check_zero
      </v-icon>
      {{ titleFilter }}
    </v-chip>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  titleFilter: string,
  disabled: boolean,
}>();

const checked = ref(false);

const emit = defineEmits<{
  (e: "input", value?: boolean): void;
}>();

function updateValue(value: boolean) {
  emit("input", value);
}

defineExpose({ checked });
</script>

<style lang="scss">

@import "../../../assets/styles/main";

.chips__filter {
  border-radius: 40px !important;
  margin-top: 2px;
  margin-bottom: 2px;
  &:not(:last-child){
    margin: unset;
    margin-right: 4px;
  }
  @include body-2;
  height: 20px !important;
  background:white !important;
  border: solid 1px $elements !important;

   &:hover {
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32) !important;
    border: none !important;
  }

  &--checked {
    background: $primary !important;
    color: white !important;
    border: none !important;
  }

  &:active {
    background-color: $primary !important;
    color: white !important;
  }

  &:focus-visible {
    border: solid 1px $primary !important;
    box-shadow: inset 0 0 0 1px #eee;
  }
}

.chips__filter--checked {

  &:hover {
    background: $primary !important;
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32) !important;
    border: none !important;
  }
}

.v-icon__component, .v-icon__svg {
  height: 20px;
  width: 20px;
}

</style>
